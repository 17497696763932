import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[MaxValue]'
})
export class MaxValueDirective {

  @Input() MaxValue!: number

  private readonly maxValueDigit: number = this.MaxValue

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  OnInputChange(event: Event) {
    const input = event.target as HTMLInputElement
    let inputElement = parseFloat(input.value)

    if (inputElement > this.MaxValue) {
      input.value = this.MaxValue.toString()
      input.dispatchEvent(new Event('input'));  // To ensure Angular binding updates
    }
  }

}
