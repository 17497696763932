<!--The content below is only a placeholder and can be replaced.-->
<apx-chart
  [series]="chartOptions.series"
  [chart]="chartOptions.chart"
  [xaxis]="chartOptions.xaxis"
  [stroke]="chartOptions.stroke"
  [dataLabels]="chartOptions.dataLabels"
  [yaxis]="chartOptions.yaxis"
  [labels]="chartOptions.labels"
  [legend]="chartOptions.legend"
  [title]="chartOptions.title"
  [subtitle]="chartOptions.subtitle"
></apx-chart>
