<!--The content below is only a placeholder and can be replaced.-->
<apx-chart
  [series]="chartOptions.series"
  [chart]="chartOptions.chart"
  [grid]="chartOptions.grid"
  [colors]="chartOptions.colors"
  [plotOptions]="chartOptions.plotOptions"
  [stroke]="chartOptions.stroke"
  [xaxis]="chartOptions.xaxis"
  [yaxis]="chartOptions.yaxis"
></apx-chart>
