import { Component, Injector, Input, OnInit } from '@angular/core';
import { AppComponentBase } from 'src/app/shared/app-component-base';

@Component({
  selector: 'app-growth-area-chart',
  templateUrl: './growth-area-chart.component.html',
  styleUrls: ['./growth-area-chart.component.scss']
})
export class GrowthAreaChartComponent extends AppComponentBase implements OnInit {

  chartOptions: any
  @Input() series: any
  @Input() title: any
  @Input() categories: any

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.chartOptions = {
      series: [
        {
          name: "Growth",
          data: [10, 5, 15, 0, 15, 12, 29, 29, 29, 12, 15, 5],
        },
      ],
      chart: {
        height: 200,
        type: "line",
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 5,
          left: 0,
          blur: 4,
          color: "#7366ff",
          opacity: 0.22,
        },
      },
      grid: {
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      colors: ["#5527FF"],
      stroke: {
        width: 3,
        curve: "smooth",
      },
      xaxis: {
        type: "category",
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan"],
        tickAmount: 10,
        labels: {
          style: {
            fontFamily: "Rubik, sans-serif",
          },
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          gradientToColors: ["#5527FF"],
          shadeIntensity: 1,
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
          colorStops: [
            {
              offset: 0,
              color: "#5527FF",
              opacity: 1,
            },
            {
              offset: 100,
              color: "#E069AE",
              opacity: 1,
            },
          ],
          // stops: [0, 100, 100, 100]
        },
      },
      yaxis: {
        min: -10,
        max: 40,
        labels: {
          show: false,
        },
      },
    };
  }

}
