import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.development';
import { HelperService } from '../../helpers/helper.service';
import { Organization } from '../../models/organization/organization';
import { filterationParams } from '../../params/params';

@Injectable({
  providedIn: 'root'
})
export class OrganizationSetupService {

  baseUrl = environment.basicUrl
  version = environment.version

  constructor(private http: HttpClient, private _helper: HelperService) { }

  getOrganizations() {
    let params = new filterationParams
    return this._helper.CallRequestList(`organization/organizations`, this._helper.CreateRequestParameters(params.notPaginationParams))
  }

  getOrganization(id: number) {
    return this._helper.CallRequestList(`organization/organizations/${id}`)
  }
  
  setupOrganization(model: Organization) {
    return this._helper.createFormData(`organization/organizations`, model)
  }
}
