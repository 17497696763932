<!-- <apx-chart
  [series]="BarBoxData?.series"
  [chart]="BarBoxData?.chart"
  [dataLabels]="BarBoxData.dataLabels"
  [plotOptions]="BarBoxData.plotOptions"
  [yaxis]="BarBoxData.yaxis"
  [xaxis]="BarBoxData.xaxis"
  [fill]="BarBoxData.fill"
  [title]="BarBoxData.title"
></apx-chart> -->

<apx-chart
  [chart]="BarBoxData.chart"
  [dataLabels]="BarBoxData.dataLabels"
  [stroke]="BarBoxData.stroke"
  [series]="BarBoxData.series"
  [title]="BarBoxData.title"
  [subtitle]="BarBoxData.subtitle"
  [fill]="BarBoxData.fill"
  [xaxis]="BarBoxData.xaxis"
  [yaxis]="BarBoxData.yaxis"
  [legend]="BarBoxData.legend"
  [responsive]="BarBoxData.responsive"
></apx-chart>
