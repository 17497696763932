<div class="container">
  <div id="receipt" hidden >
    <div class="watermark">
      <img src="assets/images/logo/reportLogo.png" alt="" />
    </div>
    <div class="content" >
      <div class="container mt-5">
        <div class="table-header">
          <div class="receipt-header text-center">
            <h2>سند قبض</h2>
            <h4>{{newTransactionDetails?.id ? 'استكمال مدفوعات' : ''}}</h4>
          </div>
        </div>

        <table class="table">
          <tbody>
            <tr>
              <td>اسم العميل</td>
              <td>{{receiptDetails?.clientName || receiptDetails?.client_name}}</td>
            </tr>
            <tr>
              <td>الرقم الضريبي / الهوية الوطنية</td>
              <td>{{receiptDetails?.national_id }}</td>
            </tr>
            <tr>
              <td>الخدمة</td>
              <td>{{receiptDetails?.incomeSource || receiptDetails?.source_name}}</td>
              <td>المبلغ الكلي بعد الضريبة</td>
              <td>{{receiptDetails?.grossCost || receiptDetails?.gross_cost}} ريال </td>
            </tr>
            <tr>
              <td>المبلغ المدفوع</td>
              <td>{{receiptDetails?.paidAmount || receiptDetails?.paid_amount}} ريال</td>
              <td>تاريخ الطباعه</td>
              <td>{{currentDate | date : "yyyy/MM/dd/h:mm"}}</td>
            </tr>
            <!-- new transaction -->
            <tr *ngIf="newTransactionDetails?.id">
              <td>حالة الدفع</td>
              <td>{{receiptDetails?.remainingAmount > +newTransactionDetails?.paid_amount ? 'مدفوع جزئيا' : 'مدفوعة'}}
              </td>
            </tr>
            <tr *ngIf="!newTransactionDetails?.id">
              <td>حالة الدفع</td>
              <td>{{receiptDetails?.remainingAmount !== '0.00'? 'مدفوع جزئيا' : 'مدفوعة'}}</td>
            </tr>
            <tr>
              <td *ngIf="receiptDetails?.remainingAmount != '0.00'">المبلغ المتبقي</td>
              <td *ngIf="receiptDetails?.remainingAmount == '0.00'">
                <del>المبلغ المتبقي</del>
              </td>
              <td>{{receiptDetails?.remainingAmount || receiptDetails?.remaining_amount || '0.00'}} ريال</td>
              <td>تاريخ الاستحقاق</td>
              <td>{{receiptDetails?.dueDate || receiptDetails?.due_date}}</td>
            </tr>

            <ng-container *ngIf="newTransactionDetails?.id">
              <tr>
                <td colspan="4">
                  -------------------------------------------------------
                </td>
              </tr>
              <tr>
                <td>المبلغ المدفوع</td>
                <td>{{newTransactionDetails?.paid_amount}} ريال</td>
                <td *ngIf="receiptDetails?.remainingAmount - +newTransactionDetails?.paid_amount > 0">
                  المبلغ المتبقي
                </td>
                <td *ngIf="receiptDetails?.remainingAmount - +newTransactionDetails?.paid_amount <= 0">
                  <del>المبلغ المتبقي</del>
                </td>
                <td>{{receiptDetails?.remainingAmount - +newTransactionDetails?.paid_amount || '0.00'}} ريال</td>
              </tr>
            </ng-container>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
