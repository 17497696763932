<div class="card">
    <div class="card-body">
      <div class="d-flex align-items-center">
        <div class="me-3">
          <i [class]="icon" style="font-size: 2em;"></i>
        </div>
        <div>
          <h5 class="card-title">{{ title }}</h5>
          <p class="card-text">{{ value }}</p>
        </div>
      </div>
    </div>
  </div>
  