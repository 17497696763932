import { Component, Injector, Input, SimpleChanges, ViewChild } from '@angular/core';
import { TableColumns } from '../../models/core.interface';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ReceiptComponent } from 'src/app/components/billing/receipt/receipt.component';
import { AppComponentBase } from '../../app-component-base';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent extends AppComponentBase {
  baseImgUrl: string = 'https://abnayiy2.s3.me-south-1.amazonaws.com/'
  transactionDetails:any;
  @ViewChild(ReceiptComponent) receiptComponent!: ReceiptComponent;

  @Input() dataList!: any[];
  @Input() subDataList!: any[];
  @Input() displayColumns!: TableColumns[];

  @Input() fileName!: string

  @Input() title!: string
  @Input() routeUrl!: string
  @Input() showActions!: string[]
  constructor(private modalService: BsModalService, injector: Injector) {
    super(injector);
  }
  log(para: any) {
    console.log(para)
  }
  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges): void {
  }
  toLang(param: { [key: string]: string }) {
    // return param['_latin']
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  editContactOfficers(id: number) {
    console.log(id)
    const initialState: ModalOptions = {
      initialState: {
        list: ['Open a modal with component', 'Pass your data', 'Do something else', '...'],
        title: 'Update Contact',
        id: id,
      }
    };

  }

  downloadReceipt(bill: any): void {
    this.receiptComponent.downloadReceipt(bill);
  }

  exportDataToExcelSheet(tableId: any, fileName: string) {
    this.dataToExport = this.dataList
    this.exportTableToExcel(tableId, fileName)
  }
}
