import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[OnlyAlphabetsNumbers]'
})
export class OnlyAlphabetsNumbersDirective {

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event']) onInput(event: KeyboardEvent) {

    const value = (event.target as HTMLInputElement).value;
    const numericValue = parseInt(value + event.key, 10);
    
    // if ((!isNaN(numericValue) && numericValue >= Math.pow(10, this.MaxDigit))) {
    //   event.preventDefault();
    // }
    
    
    if ((event.key === 'Backspace' || event.keyCode == 32) 
      || (event.keyCode >= 48 && event.keyCode <= 57)
      || (event.keyCode >= 65 && event.keyCode <= 90)
      || (event.keyCode >= 97 && event.keyCode <= 122)
      || ((event.key == 'ج' 
      || event.key == 'د' 
      || event.key == 'ذ' 
      || event.key == 'ز' 
      || event.key == 'ز' 
      || event.key == 'ط' 
      || event.key == 'ظ' 
      || event.key == 'ك'
      || event.key == 'و'))
    ) {
      return; // Allow the action
    }
    event.preventDefault();
  }

}
