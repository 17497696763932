import { Injector } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { SharedService } from './services/shared.service'
import { LoginService } from './services/login.service'
import { RolesService } from './services/roles/roles.service'
import { Permissions } from './models/roles/permissions'
import { Roles } from './enums/roles'

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { ExportAsConfig, ExportAsService } from 'ngx-export-as'

import * as ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';

import html2canvas from 'html2canvas';
// @ts-ignore
import * as html2pdf from 'html2pdf.js';
import { Margin } from '@syncfusion/ej2-angular-diagrams'
interface DropdownOption {
  label: string;
  value: string;
  icon: string;
}
export abstract class AppComponentBase {
  _translateService: TranslateService
  _toastr: ToastrService
  _roles: RolesService
  _exportAsService: ExportAsService
  authService: LoginService
  active: boolean = true
  formValid: boolean = false
  formSubmitted: boolean = false
  formReseted: boolean = false
  showList: boolean = true
  showForm: boolean = false
  editForm: boolean = false
  formId: number = 0
  pageSize: any = 10
  page: any = 1
  total: any
  userRoles = Roles

  dataToExport: any
  selectedExportOPtion: any
  exportOptions: DropdownOption[] = [
    { label: 'Excel', value: 'excel', icon: 'pi-file-excel' },
    { label: 'PDF', value: 'pdf', icon: 'pi-file-pdf' },
    { label: 'Print', value: 'print', icon: 'pi-print' },
  ];

  permissions!: Permissions[]
  public groupedPermissions!: any
  groupedPermissionsList: any = []

  haveShowPermissions: boolean = false
  haveCreatePermissions: boolean = false
  haveEditPermissions: boolean = false
  haveShowRequiredDocumentsPermissions: boolean = false
  haveCreateRequiredDocumentsPermissions: boolean = false
  haveEditRequiredDocumentsPermissions: boolean = false
  haveShowPaymentAttemptsPermissions: boolean = false
  haveShowPaymentMonitorPermissions: boolean = false

  userPermissions: any

  get isDirRTL() {
    return this._translateService.currentLang == 'ar'
  }

  constructor(injector: Injector) {
    this._translateService = injector.get(TranslateService)
    this._toastr = injector.get(ToastrService)
    this._roles = injector.get(RolesService)
    this._exportAsService = injector.get(ExportAsService)
    this.authService = injector.get(LoginService)

    this.userPermissions = JSON.parse(localStorage.getItem("user")!)?.user_permissions
  }

  cancelForm() {
    this.showList = true;
    this.showForm = false;
    this.editForm = false;
  }


  clone(source: any) {
    return JSON.parse(JSON.stringify(source))
  }

  //--------------------- Notify-------------------
  showError(message?: any, title?: any, option?: any) {
    // if (message)
    //     this._toastr.error(message)
    // if (message && title)
    //     this._toastr.error(message, title)
    // if (message && title && option)
    this._toastr.error(message, title, option)
  }

  showSuccess(message?: any, title?: any, option?: any) {
    if (!message) message = this.l('success-message')

    this._toastr.success(message, title, option)
  }

  showErrors(errors: any) {
    console.log(errors);
    console.log(errors.error.message);
    // let isErrorsArray = Array.isArray(errors)

    if (typeof errors.error.errors != 'string') {
      for (const error in errors.error.errors) {
        console.log(`${error}: ${errors.error.errors[error]}`);
        this._toastr.error(errors.error.errors[error])
      }
    } else if (errors.error.message) {
      this._toastr.error(errors.error.message)
    } else {
      this._toastr.error(errors.error.message)
    }

    if (errors.error.message) {
      this._toastr.error(errors.error.message)
    }

    if (errors.error.message.includes('Unauthenticated')) {
      this.authService.Logout()
      window.location.reload()
    }
  }

  showWarning() { }

  //-----------------------localization-------------
  l(key: any, values?: any) {
    return this._translateService.instant(key, values)
  }

  handleError(error: any) {

    if (error['error']) this.showError(this.isDirRTL ? error['error'].messageAr : error['error'].messageEn, 'Assign Error', { positionClass: 'toast-top-right' })
    else this.showError(error.message, 'Assign Error')
  }

  getUserPermissions(role: string) {
    this.groupedPermissions = this.userPermissions?.reduce((acc: any, permission: any) => {
      const groupName = permission.group_name;

      if (!acc[groupName]) {
        acc[groupName] = [];
      }

      acc[groupName].push(permission);
      return acc;
    }, {});

    if (this.groupedPermissions[role]) {

      this.haveShowPermissions = this.groupedPermissions[role].findIndex((userPermission: any) => userPermission.name.includes('show')) != -1 ? true : false
      this.haveCreatePermissions = this.groupedPermissions[role].findIndex((userPermission: any) => userPermission.name.includes('create')) != -1 ? true : false
      this.haveEditPermissions = this.groupedPermissions[role].findIndex((userPermission: any) => userPermission.name.includes('update')) != -1 ? true : false
      this.haveShowRequiredDocumentsPermissions = this.groupedPermissions[role].findIndex((userPermission: any) => userPermission.name.includes('list_required-documents')) != -1 ? true : false
      this.haveCreateRequiredDocumentsPermissions = this.groupedPermissions[role].findIndex((userPermission: any) => userPermission.name.includes('create_required-document')) != -1 ? true : false
      this.haveEditRequiredDocumentsPermissions = this.groupedPermissions[role].findIndex((userPermission: any) => userPermission.name.includes('update_required-document')) != -1 ? true : false
      this.haveShowPaymentAttemptsPermissions = this.groupedPermissions[role].findIndex((userPermission: any) => userPermission.name.includes('list_payment-attempts')) != -1 ? true : false
      this.haveShowPaymentMonitorPermissions = this.groupedPermissions[role].findIndex((userPermission: any) => userPermission.name.includes('list_payment_monitor')) != -1 ? true : false
    }
  }

  exportToExcel(data: any, headerWidth: any): void {
    // Create a worksheet from the table data
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

    console.log(headerWidth);


    // Set column widths
    worksheet['!cols'] = headerWidth;
    // worksheet['!cols'] = [
    //   { wpx: 150 },
    //   { wpx: 100 },
    //   { wpx: 200 },
    //   { wpx: 200 },
    //   { wpx: 200 },
    //   { wpx: 200 },
    //   { wpx: 200 },
    //   { wpx: 200 },
    //   { wpx: 200 },
    //   { wpx: 200 },
    //   { wpx: 200 },
    //   { wpx: 200 },
    //   { wpx: 200 },
    //   { wpx: 200 },
    //   { wpx: 200 },
    //   { wpx: 200 },
    // ];

    // Create a new workbook and append the worksheet
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Write the workbook to a buffer and save as a file
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'table_data');
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(data, `${fileName}_${new Date().getTime()}.xlsx`);
  }

  exportToExcels(tableId: any, tableName: any): void {
    console.log(tableId);
    console.log(tableName);

    let exportAsConfig: ExportAsConfig = {
      type: 'xlsx',
      elementIdOrContent: tableId,
      options: {
        // You can specify column widths here
        columnWidths: [250, 250, 250, 250, 250, 250, 250, 250, 250, 250, 250, 250, 250, 250, 250, 250] // Example widths for 3 columns
      }
    }

    console.log(exportAsConfig);

    this._exportAsService.save(exportAsConfig, tableName).subscribe(() => {
      // save started
    })
  }

  exportHtmlTableToXlsx(tableId: string, fileName: string, columnWidths: number[]): void {
    const table = document.getElementById(tableId);

    const ws: XLSX.WorkSheet | any = XLSX.utils.table_to_sheet(table);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    if (columnWidths && columnWidths.length > 0) {
      ws['!cols'] = [];
      columnWidths.forEach((width: number) => {
        ws['!cols'].push({ width });
      });
    }

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    saveAs(new Blob([excelBuffer]), `${fileName}.xlsx`);
  }

  exportAsExcelFile(
    reportHeading: string,
    reportSubHeading: string,
    headersArray: any[],
    json: any[],
    excelFileName: string,
    sheetName: string
  ) {
    // const header = headersArray
    // const data = json

    // const workbook = new Workbook();
    // workbook.creator = 'Snippet Codder'
    // workbook.lastModifiedBy = 'SnippetCodder'
    // workbook.created = new Date()
    // workbook.modified = new Date()

    // const worksheet = workbook.addWorksheet(sheetName);
    // worksheet.addRow([])
    // // worksheet.mergeCells('A1:' + this.num)
    // worksheet.getCell('A1').value = reportHeading
    // worksheet.getCell('A1').alignment = { horizontal: 'center' }
    // worksheet.getCell('A1').font = { size: 15, bold: true }

    // const headerRow = worksheet.addRow(header)

    // headerRow.eachCell((cell, index) => {
    //   cell.fill = {
    //     type: 'pattern',
    //     pattern:'solid',
    //     fgColor: { argb: 'FFFFFF00' },
    //     bgColor: { argb: 'FF0000FF' }
    //   }
    //   cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    //   cell.font = { size: 12, bold: true }

    //   worksheet.getColumn(index).width = header[index - 1].length < 20 ? 20 : header[index - 1].length
    // })

    // let columnsArray: any[];
    // for (const key in json) {
    //   if(json.hasOwnProperty(key)) {
    //     columnsArray = Object.keys(json[key]);
    //   }
    // }

    // data.forEach((element: any) => {
    //   const eachRow = [];
    //   columnsArray.forEach(column => {
    //     eachRow.push(element[column]);
    //   });


    // })

    // workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
    //   const blob = new Blob([data], { type: EXCEL_TYPE });
    //   fs.saveAs(blob, `${excelFileName}_.xlsx`);
    // });
  }

  async exportTableToExcel(tableId: string, fileName: string): Promise<void> {
    // Reference the HTML table
    const table = document.getElementById(tableId);
    if (!table) {
      console.error(`Table with id ${tableId} not found!`);
      return;
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // Load the logo image
    const response = await fetch('/assets/images/logo/reportLogo.png');
    const imageBlob = await response.blob();
    const arrayBuffer = await new Response(imageBlob).arrayBuffer();
    const uint8Array = new Uint8Array(arrayBuffer);

    // Add the image to the workbook
    const imageId = workbook.addImage({
      buffer: uint8Array,
      extension: 'png', // Change if the image is in a different format
    });

    // Position the image at the top of the worksheet
    worksheet.addImage(imageId, {
      tl: { col: 0, row: 0 },
      ext: { width: 500, height: 100 }
    });

    // Adjust row height to make space for the image
    worksheet.getRow(1).height = 100;

    // Get the table rows
    const rows = table.querySelectorAll('tr');
    rows.forEach((row, rowIndex) => {
      const cells = row.querySelectorAll('th, td');
      cells.forEach((cell, cellIndex) => {
        const excelCell = worksheet.getRow(rowIndex + 4).getCell(cellIndex + 1); // Start from row 2
        excelCell.value = cell.textContent;

        // Apply styles
        if (rowIndex === 0) { // Style header cells
          excelCell.font = { bold: true };
          excelCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFF00' } // Yellow background
          };
          excelCell.alignment = { vertical: 'middle', horizontal: 'center' };
        } else { // Center align other cells
          excelCell.alignment = { vertical: 'middle', horizontal: 'center' };
        }
      });
    });

    // Set column widths to fit content
    worksheet.columns.forEach((column: any) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell: any) => {
        const columnLength = cell.value ? cell.value.toString().length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength + 2;
    });

    // Save the workbook to a file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, `${fileName}.xlsx`);
    });
  }

  async exportTableToPDF(tableId: string, fileName: string): Promise<void> {
    const element = document.getElementById(tableId);
    element!.style.marginTop = '120px'

    if (!element) {
      console.error(`Element with id ${tableId} not found.`);
      return;
    }

    const options = {
      margin: 0,
      filename: fileName + '.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' as 'portrait' | 'landscape' },
    };

    // Load the logo image
    const logoImage = new Image();
    logoImage.src = '/assets/images/logo/reportLogo.png';

    // Wait for the logo image to load
    logoImage.onload = async () => {
      const pdf = await html2pdf().from(element).set(options).toPdf().get('pdf');

      // Add the logo to the PDF
      pdf.addImage(logoImage, 'JPEG', 10, 10, 50, 20); // Adjust dimensions as needed

      // Save the PDF
      pdf.save(fileName + '.pdf');
    };
  }

}
