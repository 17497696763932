import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class rolesGuard  {
  constructor(public router: Router, private toastr: ToastrService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Guard for user is login or not
    let userPermissions = JSON.parse(localStorage.getItem("user")!)?.user_permissions

    let filteredUserPermissions = userPermissions?.reduce((acc: any, permission: any) => {
      const groupName = permission.group_name;
    
      if (!acc[groupName]) {
        acc[groupName] = [];
      }
    
      acc[groupName].push(permission);
      return acc;
    }, {});

    const routeRoles :any[] = route.data['role'];

    // console.log(filteredUserPermissions);
    // console.log(routeRoles);
    // console.log(Object.keys(filteredUserPermissions).findIndex((permission: any) => permission == routeRoles[0]));
    // console.log(Object.keys(filteredUserPermissions));
    // console.log(filteredUserPermissions[routeRoles[0]]);

    // console.log(filteredUserPermissions[routeRoles[0]].findIndex((permission: any) => permission.name.includes(routeRoles[1])));

    
    

    if(Object.keys(filteredUserPermissions).findIndex((permission: any) => permission == routeRoles[0]) == -1) {
      this.router.navigate(["/error-page/no-access"]);
      return true;
    }
    if (filteredUserPermissions[routeRoles[0]]) {
      if (filteredUserPermissions[routeRoles[0]].findIndex((permission: any) => permission.name.includes(routeRoles[1])) == -1) {
        this.router.navigate(["/error-page/no-access"]);
        return true;
      }
    }
    
    return true;
  }
}
