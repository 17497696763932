import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import { io } from 'socket.io-client'
// import io from 'socket.io-client/dist/socket.io.js';
declare var io: any;


@Injectable({
  providedIn: 'root'
})
export class SocketService {

  // socket: any;
  // readonly url: string = 'https://sockets.abnaey.com'


  // constructor() { 
  //   this.socket = io(this.url, {secure: true})
  // }

  // listen(eventName: string) {
  //   // debugger;
  //   this.socket.on(eventName, (data: any) => {
  //     console.log(data);
  //   })
  // }
  // listen(eventName: string) {
  //   return new Observable((subscriber) => {
  //     this.socket.on(eventName, (data: any) => {
  //       subscriber.next(data)
  //     })
  //   })
  // }

  // emit(eventName: string, data: any) {
  //   this.socket.emit(eventName, data)
  // }
}
