import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from 'src/app/shared/services/layout.service';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit {

  constructor(
    public layout: LayoutService,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.layout.config.settings.layout = params['layout'] ? params['layout'] : this.layout.config.settings.layout
    })
  }

  items!: MenuItem[];

  ngOnInit(): void {
    this.items = [
      {
          label: this.translate.currentLang == 'ar' ? 'إعدادات المؤسسة' : 'organization setup',
          routerLink: 'organization-setup'
      },
      {
          label: this.translate.currentLang == 'ar' ? 'إعدادات مجمع المدارس' : 'school hub setup',
          routerLink: 'school-hup-setup'
      },
      {
          label: this.translate.currentLang == 'ar' ? 'إعدادات النوع' : 'gender setup',
          routerLink: 'gender-setup'
      },
      {
          label: this.translate.currentLang == 'ar' ? 'إعدادات المسار' : 'branch setup',
          routerLink: 'branch-setup'
      },
      {
          label: this.translate.currentLang == 'ar' ? 'إعدادات المدارس' : 'school setup',
          routerLink: 'school-setup'
      },
      {
        label: this.translate.currentLang == 'ar' ? 'إعدادات مجموعة المستويات' : 'group degree setup',
        routerLink: 'group-degree-setup'
      },
      {
        label: this.translate.currentLang == 'ar' ? 'إعدادات المستويات' : 'degree setup',
        routerLink: 'degree-setup'
      },
  ];
  }

}
