import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[creditInput]'
})
export class CreditInputDirective {

  @Input() creditInput!: string;

  constructor(private ngControl: NgControl, private el: ElementRef) { }

  @HostListener('blur', ['$event'])
  onBlur(event: KeyboardEvent | any) {
    const currentValue: string = this.ngControl.value;

    let year = new Date().getFullYear()
    
    
    if (event.target.value.length == 1 && this.creditInput != 'cardNumber') {
      this.ngControl.control?.setValue('0' + currentValue);
    }

    if (this.creditInput == 'year' && event.target.value < Number(year.toString().slice(-2))) {
      this.ngControl.control?.setValue(''); 
    }

    if (this.creditInput == 'month' && event.target.value > 12) {
      this.ngControl.control?.setValue(''); 
    }
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {

    const value = (event.target as HTMLInputElement).value;
    // const numericValue = parseInt(value + event.key, 10);
    // const numericValue = parseInt(value + event.key, 10);
    // const numericValue = Number(event.key);
    // console.log(numericValue);
    // console.log(typeof(numericValue));
    
    // if ((!isNaN(numericValue) && numericValue >= Math.pow(10, this.MaxDigit))) {
    //   event.preventDefault();
    // }zz
    
    // var numericValue = Number(value.replace(/\s/g, ''));


    // (
    //   (event.key == 'Backspace' || event.key === 'Delete') || 
    //   (event.key != 'Backspace' && event.key != 'Delete') && 
    //   (event.key.toString().length < 19)
    // )
    
    if (this.creditInput == 'cardNumber') {
      if (
        (event.key == 'Backspace' || event.key === 'Delete') || (value.length < 19)
      ) {
        return; // Allow the action
      }
        event.preventDefault();
    }
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    if (value.length > 2 && this.creditInput != 'cardNumber') {
      this.ngControl.control?.setValue(value.substr(1)); 
    }

    if (value.length == 4 || value.length == 9 || value.length == 14) {
      this.ngControl.control?.setValue(value + ' '); 
    }
    
  }

}
