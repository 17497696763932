import { Component, Input } from '@angular/core';
// @ts-ignore
import * as html2pdf from 'html2pdf.js';
@Component({
  selector: 'income-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent {
  @Input() receiptDetails: any;
  @Input() newTransactionDetails: any

  currentDate = new Date();
  constructor() { }


  downloadReceipt(bill?: any): void {
    if (bill?.id) {
      this.receiptDetails = bill
    }
    console.log(this.receiptDetails);


    const options = {
      margin: 10,
      filename: 'receipt.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    var element = document.getElementById('receipt')

    // element!.style.opacity = '1'
    element?.removeAttribute('hidden');

    html2pdf().from(element).set(options).outputPdf().then((output: any) => {
      // element!.style.opacity = '1'
      element?.setAttribute('hidden', 'true')
    }).save()

  }
  // element!.style.opacity = '0.5'

}
