import {
  Component,
  Injector,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AppComponentBase } from 'src/app/shared/app-component-base';

@Component({
  selector: 'app-column-chart-with-data-labels',
  templateUrl: './column-chart-with-data-labels.component.html',
  styleUrls: ['./column-chart-with-data-labels.component.scss'],
})
export class ColumnChartWithDataLabelsComponent
  extends AppComponentBase
  implements OnInit, OnChanges
{
  @Input() series: any;
  @Input() categories: any;
  @Input() titleLabel: any;

  BarBoxData: any;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    // Initialize chart data only after inputs are available
    this.initializeChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['series'] || changes['categories'] || changes['titleLabel']) {
      this.initializeChart();
    }
  }

  initializeChart() {
    console.log('Series:', this.series);
    console.log('Categories:', this.categories);
    console.log('Title:', this.titleLabel);

    this.BarBoxData = {
      chart: {
        height: 350,
        type: 'bar',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val;
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },
      series: this.series,
      title: {
        text: this.titleLabel,
        align: 'left',
        style: {
          fontSize: '12px',
        },
      },
      fill: {
        colors: ['#7366ff'],
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: 'vertical',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 0.8,
          stops: [0, 100],
        },
      },
      xaxis: {
        categories: this.categories,
        position: 'top',
        labels: {
          offsetY: -18,
          style: {
            fontSize: '12px',
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
      },
      yaxis: {
        decimalsInFloat: 1,
      },
      legend: {
        show: true,
      },
      responsive: [
        {
          breakpoint: 1366,
          options: {
            subtitle: {
              style: {
                fontSize: '18px',
              },
            },
          },
        },
        {
          breakpoint: 992,
          options: {
            subtitle: {
              style: {
                fontSize: '16px',
              },
            },
          },
        },
      ],
    };
  }
}
