import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { UserData } from "src/app/shared/models/user";
import { LoginService } from "src/app/shared/services/login.service";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  public userName!: string;
  public profileImg!: "assets/images/dashboard/profile.jpg";

  user!: UserData | null

  constructor(public router: Router, private auth: LoginService, public translate: TranslateService) {
    if (JSON.parse(localStorage.getItem("user")!)) {
      this.user = JSON.parse(localStorage.getItem("user")!).user
    } else {
      this.user = null
    }
  }

  ngOnInit() {}

  logoutFunc() {
    this.router.navigateByUrl('auth/login-as');
    this.auth.Logout()
    window.location.reload()
  }
}
