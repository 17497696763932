import {
  Component,
  PLATFORM_ID,
  Inject,
  OnInit,
  HostListener,
} from '@angular/core';
// import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { LoginService } from './shared/services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InactivityService } from './shared/services/inactivity.service';
import { SocketService } from './shared/services/socket.service';
import { MapLoaderService } from './shared/services/map-loader.service';
// import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  currentLang: string | any;

  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map((v) => v[1])
  );

  private inactivityTimeout: any;
  private inactivityPeriod = 1 * 60; // 5 minutes in milliseconds

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    private loader: LoadingBarService,
    private loginService: LoginService,
    public translate: TranslateService,
    public router: Router,
    private toastr: ToastrService,
    private socket: SocketService,
    private mapLoaderService: MapLoaderService,
    private inactivityService: InactivityService
  ) {
    // if (isPlatformBrowser(this.platformId)) {
    //   translate.setDefaultLang('en');
    //   translate.addLangs(['en', 'de', 'es', 'fr', 'pt', 'cn', 'ae']);
    // }

    this.currentLang = localStorage.getItem('currentLang') || 'ar';
    this.translate.addLangs(['ar', 'en']);
    this.translate.setDefaultLang('ar');
    this.translate.use(this.currentLang);
  }

  ngOnInit(): void {
    this.setCurrentUser();
    // this.checkIfTokenIsExpired()

    this.currentLang = localStorage.getItem('currentLang') || 'ar';
    this.translate.addLangs(['ar', 'en']);
    this.translate.setDefaultLang('ar');
    this.translate.use(this.currentLang);
    this.document.documentElement.lang = localStorage.getItem('currentLang')!;
    this.mapLoaderService.loadGoogleMaps();

    // this.document.body.classList.add('dark-sidebar')
    this.inactivityService.resetTimer();
  }

  @HostListener('window:mousemove', ['$event'])
  @HostListener('window:keydown', ['$event'])
  onMouseMove() {
    this.inactivityService.inactivityPeriod =
      this.inactivityService.inactivityTime * 60;
  }

  setCurrentUser() {
    const user = localStorage.getItem('user');
    this.loginService.setCurrentUser(JSON.parse(user!));
  }

  // checkIfTokenIsExpired() {
  //   const isExpired = this.loginService.isTokenExpired();
  //   if (isExpired) {
  //     // Token is expired
  //     this.toastr.error("Token is expired")
  //     this.router.navigate(["/auth/login"]);
  //   }
  // }
}
