import { Component, Injector, Input, OnInit } from '@angular/core';
import { AppComponentBase } from 'src/app/shared/app-component-base';

@Component({
  selector: 'app-distributed-treemap',
  templateUrl: './distributed-treemap.component.html',
  styleUrls: ['./distributed-treemap.component.scss']
})
export class DistributedTreemapComponent extends AppComponentBase implements OnInit {

  chartOptions: any
  @Input() series: any
  @Input() title: any

  constructor(injector: Injector) {
    super(injector)
  }

  ngOnInit(): void {
    this.chartOptions = {
      series: this.series,
      legend: {
        show: false
      },
      chart: {
        height: 350,
        type: "treemap"
      },
      title: {
        text: this.title,
        align: "center"
      },
      colors: [
        "#3B93A5",
        "#F7B844",
        "#ADD8C7",
        "#EC3C65",
        "#CDD7B6",
        "#C1F666",
        "#D43F97",
        "#1E5D8C",
        "#421243",
        "#7F94B0",
        "#EF6537",
        "#C0ADDB"
      ],
      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: false
        }
      }
    };
  }

}
