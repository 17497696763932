<div class="card">
    <div class="card-header">
      <div class="header-top">
        <!-- <h5>Order Status</h5> -->
      </div>
    </div>
    <div class="card-body">
      <div class="chart-container progress-chart" *ngFor="let chartConfig of chartConfigs">
        <div>
            <apx-chart 
            [chart]="chartConfig.chart" 
            [plotOptions]="chartConfig.plotOptions" 
            [colors]="chartConfig.colors" 
            [stroke]="chartConfig.stroke" 
            [fill]="chartConfig.fill" 
            [series]="chartConfig.series" 
            [title]="chartConfig.title" 
            [subtitle]="chartConfig.subtitle"
            [tooltip]="chartConfig.tooltip"
            [xaxis]="chartConfig.xaxis" 
            [yaxis]="chartConfig.yaxis" 
            [responsive]="chartConfig.responsive">
          </apx-chart>
        </div>
      </div>
    </div>
  </div>
  