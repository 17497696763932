import { Injectable } from '@angular/core';
import { Roles } from '../../models/roles/roles';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.development';
import { HelperService, ParameterClass } from '../../helpers/helper.service';
import { filterationParams } from '../../params/params';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  baseUrl = environment.basicUrl
  version = environment.version

  constructor(private http: HttpClient, private _helper: HelperService) { }

  getRoles() {
    let params = new filterationParams
    return this._helper.CallRequestList(`users/roles`, this._helper.CreateRequestParameters(params.notPaginationParams))
  }

  getPermissions() {
    let params = new filterationParams
    return this._helper.CallRequestList(`users/permissions`, this._helper.CreateRequestParameters(params.notPaginationParams))
  }

  getRole(id: number) {
    return this._helper.CallRequestList(`users/roles/${id}`)
  }

  createRole(model: Roles) {
    return this._helper.createFormData(`users/roles`, model)
  }

  updateRole(id: number, model: Roles) {
    let params: ParameterClass[] = [
      new ParameterClass('_method', 'put')
    ];
    return this._helper.PutJsonData(`users/roles/${id}`, model, this._helper.CreateRequestParameters(params))
  }
}
