import { AfterContentChecked, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import * as feather from 'feather-icons';
import { LayoutService } from '../../../services/layout.service';
import { Menu, NavService } from '../../../services/nav.service';
import { fadeInAnimation } from 'src/app/shared/data/router-animation';
import { filter } from 'rxjs';


@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  animations: [fadeInAnimation]
})
export class ContentComponent implements OnInit, AfterViewInit{

  @ViewChild('o') o: any
  loading: boolean = true;
  currentRoute: any;
  isDashboard: boolean = false;
  public menuItems!: Menu[];

  constructor(private route: ActivatedRoute, private router: Router, public navServices: NavService, 
    public layout: LayoutService, private cdr: ChangeDetectorRef, private ngZone: NgZone) {
      
      this.route.queryParams.subscribe((params) => {
        this.layout.config.settings.layout = params['layout'] ? params['layout'] : this.layout.config.settings.layout
      })

      // if (localStorage.getItem('user')) {
      //   this.menuItems = this.navServices.processMenuItems();
      //   console.log(this.menuItems);
      // }
      // if (this.menuItems[0].type == 'link') this.router.navigate([this.menuItems[0].path])
      // if (this.menuItems[0].type == 'sub') this.router.navigate([this.menuItems[0]?.children![0].path])

      this.router.events.pipe(
        filter(e => e instanceof NavigationEnd)
      ).subscribe((ev: any) => {
        this.currentRoute = ev.url
        console.log(`this.currentRoute`, this.currentRoute);
        // console.log(this.currentRoute.includes('dashboard'));
        this.isDashboard = this.currentRoute.includes('dashboard') ? true : false;

        if (this.currentRoute === '/') {
          this.menuItems = this.navServices.processMenuItems();
          console.log(this.menuItems);
          if (this.menuItems[0].type == 'link') {
            this.router.navigate([this.menuItems[0].path])
          }
  
          if (this.menuItems[0].type == 'sub') {
            this.router.navigate([this.menuItems[0]?.children![0].path])
          }
        }

        
      })
  }
    
  ngAfterViewInit() {
    this.cdr.detectChanges()
    Promise.resolve().then(() => {
      feather.replace()
      this.getRouterOutletState(this.o)
    })
  }

  getRouterOutletState(outlet: any) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }


  get layoutClass() {
    switch(this.layout.config.settings.layout){
      case "Dubai":
        return "compact-wrapper"
      case "London":
        return "compact-wrapper only-body"
      case "Seoul":
        return "compact-wrapper modern-type"
      case "LosAngeles":
        return this.navServices.horizontal ? "horizontal-wrapper material-type" : "compact-wrapper material-type"
      case "Paris":
        return "compact-wrapper dark-sidebar"
      case "Tokyo":
        return "compact-sidebar"
      case "Madrid":
        return "compact-wrapper color-sidebar"
      case "Moscow":
        return "compact-sidebar compact-small"
      case "NewYork":
        return "compact-wrapper box-layout"
      case "Singapore":
        return this.navServices.horizontal ? "horizontal-wrapper enterprice-type" : "compact-wrapper enterprice-type"
      case "Rome":
        return "compact-sidebar compact-small material-icon"
      case "Barcelona":
        return this.navServices.horizontal ? "horizontal-wrapper enterprice-type advance-layout" : "compact-wrapper enterprice-type advance-layout"
      case "horizontal-wrapper":
        return this.navServices.horizontal ? "horizontal-wrapper" : "compact-wrapper"
      default:
        return "compact-wrapper"
    }
  }
  
  ngOnInit() {
  }

}
