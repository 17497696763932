import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[noLeadingZero]'
})
export class NoLeadingZeroDirective {

  constructor(private ngControl: NgControl, private el: ElementRef) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent | any) {
    const currentValue: string = this.ngControl.value;
    const selectionStart: number = event.target['selectionStart'];
    const selectionEnd: number = event.target['selectionEnd'];
    const keyPressed: string = event.key;
    
    if (currentValue === '' && keyPressed === '0') {
      event.preventDefault();
    }
    
    else if (keyPressed === '0' && selectionStart == 0 && selectionEnd == 0) {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    if (this.el.nativeElement.selectionStart == 1 && value.startsWith('0')) {
      this.ngControl.control?.setValue(value.substr(1)); 
    }
  }

}
