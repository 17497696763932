<div class="setup-organization">
  <div class="container">
    <div class="header">
      <h3 class="title">{{'Setup.Enter Required Data' | translate}}</h3>
    </div>
    <div class="steps">
      <p-steps [model]="items" [readonly]="true"></p-steps>
    </div>
    <div class="step-data shadow-sm">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>