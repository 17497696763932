import { Component, Injector, Input, OnInit } from '@angular/core';
import { AppComponentBase } from 'src/app/shared/app-component-base';

@Component({
  selector: 'app-custom-data-labels-bar-chart',
  templateUrl: './custom-data-labels-bar-chart.component.html',
  styleUrls: ['./custom-data-labels-bar-chart.component.scss']
})
export class CustomDataLabelsBarChartComponent extends AppComponentBase implements OnInit {

  chartOptions: any
  @Input() series: any
  @Input() categories: any
  @Input() title: any

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.chartOptions = {
      series: this.series,
      chart: {
        type: "bar",
        height: 800
      },
      plotOptions: {
        bar: {
          barHeight: "100%",
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: "bottom"
          }
        }
      },
      colors: [
        "#33b2df",
        "#546E7A",
        "#d4526e",
        "#13d8aa",
        "#A5978B",
        "#2b908f",
        "#f9a3a4",
        "#90ee7e",
        "#f48024",
        "#69d2e7"
      ],
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["#666"],
          fontWeight: ["normal"],
          fontSize: "14px",
          // fontFamily: ["cairo"]
        },
        formatter: function(val: any, opt: any) {
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
        },
        offsetX: 0,
        dropShadow: {
          enabled: false
        }
      },
      stroke: {
        width: 1,
        colors: ["#fff"]
      },
      xaxis: {
        categories: this.categories
      },
      yaxis: {
        labels: {
          show: false
        }
      },
      title: {
        text: this.title,
        align: "center",
        floating: true
      },
      // subtitle: {
      //   text: "Category Names as DataLabels inside bars",
      //   align: "center"
      // },
      tooltip: {
        theme: "dark",
        x: {
          show: false
        },
        y: {
          title: {
            formatter: function() {
              return "";
            }
          }
        }
      }
    };
  }

}
