import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[startPhoneFive]'
})
export class StartPhoneFiveDirective {

  constructor(private ngControl: NgControl) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent | any) {
    const currentValue: string = this.ngControl.value;
    const keyPressed: string = event.key;

    if (currentValue == '' && keyPressed != '5') {
      event.preventDefault();
    }
  }

}
