<div class="mb-3">
  <p-dropdown
    [options]="options"
    [(ngModel)]="selectedOption"
    [formControlName]="formControlName"
    [optionLabel]="optionLabel"
    [filter]="showSearchBar"
    [filterBy]="optionLabel"
    [showClear]="showClearButton"
    placeholder="{{ label | translate }}"
    (onChange)="onSelectionChange($event)"
    (onClear)="onClear()"
  >
    <ng-template pTemplate="selectedItem" let-selectedOption>
      <div class="flex align-items-center gap-2">
        <div>{{ selectedOption[optionLabel] }}</div>
      </div>
    </ng-template>
    <ng-template let-item pTemplate="item">
      <div class="flex align-items-center gap-2">
        <div>{{ item[optionLabel] }}</div>
      </div>
    </ng-template>
  </p-dropdown>
</div>
