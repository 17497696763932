// src/app/services/app-config.service.ts
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private readonly basicUrl: string = environment.basicUrl;
  private readonly includesYamama: boolean =
    environment.basicUrl.includes('yamama');
    private readonly includesAjial: boolean =
    environment.basicUrl.includes('ajial');

  constructor() {}

  getBasicUrl(): string {
    return this.basicUrl;
  }

  isYamama(): boolean {
    return this.includesYamama;
  }
  isAjial(): boolean {
    return this.includesAjial;
  }
}
