import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '../../../../services/nav.service';
import { LayoutService } from 'src/app/shared/services/layout.service';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent implements OnInit {

  public layoutType: string = "ltr";

  public language: boolean = false;

  public languages: any[] = [
    {
      language: 'English',
      code: 'en',
      type: 'US',
      icon: 'us'
    },
    {
      language: 'Arabic',
      code: 'ar',
      icon: 'sa'
    }
  ]

  public selectedLanguage: any = {
    language: localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')!).language : 'Arabic',
    code: localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')!).code : 'ar',
    type: localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')!).type : 'AR',
    icon: localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')!).icon : 'sa'
  }

  currentLang!: string

  constructor(public navServices: NavService, private translate: TranslateService, public layout: LayoutService) { }
  // 
  ngOnInit() {
    this.currentLang = localStorage.getItem("currentLang") || "ar";
    this.translate.use(this.currentLang)
  }

  changeLanguage(lang: any) {
    this.translate.use(lang.code)
    this.selectedLanguage = lang;
    localStorage.setItem('currentLang', lang.code);
    localStorage.setItem('language', JSON.stringify(lang));
    // window.location.reload()
  }

  customizeLayoutType(val: any) {
    this.layoutType = val;
    this.layout.config.settings.layout_type = val;
    if (val == "rtl") {
      document.getElementsByTagName("html")[0].setAttribute("dir", val);
      document.body.className = "rtl";
    } else if (val == "box-layout") {
      document.getElementsByTagName("html")[0].setAttribute("dir", val);
      document.body.className = "box-layout";
    } else {
      document.getElementsByTagName("html")[0].removeAttribute("dir");
      document.body.className = "";
    }
  }

}
