<google-map
  height="450px"
  width="100%"
  [center]="center"
  [zoom]="zoom"
  (mapClick)="moveMap($event)"
  (mapMousemove)="move($event)"
>
  <map-marker *ngIf="markerPosition" [position]="markerPosition"></map-marker>
</google-map>
