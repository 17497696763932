<div class="card">
  <div class="card-header">
    <div class="header-top">
      <!-- <h5>Order Status</h5> -->
    </div>
  </div>
  <div class="card-body">
    <div class="chart-container" *ngIf="chartConfig">
      <apx-chart
        [series]="chartConfig.series"
        [chart]="chartConfig.chart"
        [plotOptions]="chartConfig.plotOptions"
        [dataLabels]="chartConfig.dataLabels"
        [stroke]="chartConfig.stroke"
        [grid]="chartConfig.grid"
        [colors]="chartConfig.colors"
        [xaxis]="chartConfig.xaxis"
        [yaxis]="chartConfig.yaxis"
        [fill]="chartConfig.fill"
        [legend]="chartConfig.legend"
        [responsive]="chartConfig.responsive"
      ></apx-chart>
    </div>
  </div>
</div>
