<div class="login-card">
  <div>
    <div class="login-main">
      <div>
        <a class="logo" routerLink="/">
          <!-- <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="looginpage" /> -->
          <!-- <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage" /> -->
          <img class="img-fluid for-light" src="assets/images/logo/report_logo.svg" alt="looginpage" />
          <img class="img-fluid for-dark" src="assets/images/logo/report_logo.svg" alt="looginpage" />
        </a>
      </div>
      <form class="theme-form" [formGroup]="resetPasswordForm">
        <h4>{{ 'Reset Your Password' | translate}}</h4>
        <div class="d-flex ai-c flex-col login-gap">
          <div class="form-group">
            <label class="col-form-label">{{'Forms.Enter Your Mobile Number' | translate}}</label>
            <div class="row">
              <div class="col-md-3">
                <input class="form-control mb-1" type="text" value="+ 996" readonly>
              </div>
              <div class="col-md-9">
                <input class="form-control mb-1" type="tel" placeholder="0000000000" formControlName="phone" onlyNumbers [MaxDigit]="9">
                <app-input-error
                  [control]="resetPasswordForm.get('phone')"
                  [formSubmitted]="formSubmitted"
                ></app-input-error>
              </div>
              <div class="col-12">
                <button *ngIf="!codeSent" class="btn btn-primary d-block w-100 m-t-10" type="button" (click)="sendCode()">{{'Send' | translate}}</button>
              </div>
            </div>
          </div>
          <ng-container *ngIf="codeSent">
            <div class="text-center"><span class="reset-password-link">{{"If don't receive OTP" | translate}}  <a class="btn-link text-danger" (click)="sendCode()">{{'Resend' | translate}}</a></span></div>
            <div class="form-group">
              <label class="col-form-label pt-0">{{'Enter OTP' | translate}}</label>
              <div class="row otpValues">
                <div class="col">
                  <input class="form-control text-center opt-text" type="text" placeholder="0" [(ngModel)]="codeFirstDigit" [ngModelOptions]="{standalone: true}"  maxlength="1" (input)="focusOnSecond($event)">
                </div>
                <div class="col">
                  <input class="form-control text-center opt-text" type="text" placeholder="0" [(ngModel)]="codeSecondDigit" [ngModelOptions]="{standalone: true}"  maxlength="1" (input)="focusOnSecond($event)">
                </div>
                <div class="col">
                  <input class="form-control text-center opt-text" type="text" placeholder="0" [(ngModel)]="codeThirdDigit" [ngModelOptions]="{standalone: true}"  maxlength="1" (input)="focusOnSecond($event)">
                </div>
                <div class="col">
                  <input class="form-control text-center opt-text" type="text" placeholder="0" [(ngModel)]="codeFourthDigit" [ngModelOptions]="{standalone: true}"  maxlength="1">
                </div>
              </div>
            </div>
            <div class="col-12">
              <button class="btn btn-primary d-block w-100 m-t-10" type="button" (click)="verifyCode()">{{'Verify' | translate}}</button>
            </div>
          </ng-container>
          <ng-container *ngIf="codeSent && codeVerified">
            <h6 class="mt-4">{{'Create Your Password' | translate}}</h6>
            <div class="form-group">
              <input class="form-control" [type]="show ? 'text' : 'password'" name="password" formControlName="password" required="" placeholder="{{'New Password' | translate}}">
              <div class="show-hide" (click)="showPassword()" *ngIf="!show">
                <div class="img">
                  <img src="assets/images/icons/show_pass.svg" alt="">
                </div>
              </div>
              <div class="show-hide" (click)="showPassword()" *ngIf="show">
                <div class="img">
                  <img src="assets/images/icons/hide_pass.svg" alt="">
                </div>
              </div>
              <div *ngIf="resetPasswordForm.controls['password'].touched && resetPasswordForm.controls['password'].errors?.['required']" class="text text-danger mt-1">{{'Validations.Password Is Required' | translate}}</div>
            </div>
            <div class="form-group">
              <label class="col-form-label">{{'Retype Password' | translate}}</label>
              <input class="form-control" type="password" name="password_confirmation" formControlName="password_confirmation" required="" placeholder="{{'Retype Password' | translate}}">
              <div *ngIf="resetPasswordForm.controls['password_confirmation'].touched && resetPasswordForm.controls['password_confirmation'].errors?.['required']" class="text text-danger mt-1">{{'Validations.Password Is Required' | translate}}</div>
            </div>
            <div class="form-group mb-0">
              <button class="btn btn-primary d-block w-100" type="button" (click)="resetPassword()">{{'Change' | translate}}</button>
            </div>
          </ng-container>
          <p class="mt-4 mb-0">{{'Already Have Password' | translate}} <a class="ms-2" [routerLink]="'/auth/login'">{{'Forms.Sign In' | translate}}</a></p>
        </div>
      </form>
    </div>
  </div>
</div>
