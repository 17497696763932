<!--The content below is only a placeholder and can be replaced.-->
<apx-chart
  [series]="chartOptions.series"
  [chart]="chartOptions.chart"
  [xaxis]="chartOptions.xaxis"
  [dataLabels]="chartOptions.dataLabels"
  [grid]="chartOptions.grid"
  [stroke]="chartOptions.stroke"
  [title]="chartOptions.title"
></apx-chart>
