import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  @Input() center: google.maps.LatLngLiteral = {
    lat: 22.2736308,
    lng: 70.7512555,
  };

  markerPosition: google.maps.LatLngLiteral | null = null;

  constructor() {}

  ngOnInit(): void {
    this.addInitialMarker();
  }

  display: any;

  zoom = 10;

  addInitialMarker() {
    this.markerPosition = this.center;
  }

  moveMap(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) {
      this.center = event.latLng.toJSON();
    }
  }

  move(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.display = event.latLng.toJSON();
  }
}
