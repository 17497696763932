import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[decimalInput]'
})
export class DecimalInputDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInputChange(event: Event) {
    const inputValue: string = this.el.nativeElement.value

    //remove non numeric values
    let cleanValue = inputValue.replace(/[^0-9.]/g, '')

    //ensure that it is only one dot
    const dotIndex = cleanValue.indexOf('.')
    // if (dotIndex !== -1 && cleanValue.lastIndexOf('.') != dotIndex) {
    //   console.log(dotIndex);
    //   console.log(cleanValue.lastIndexOf('.'));
      
    //   cleanValue = cleanValue.substring(dotIndex + 1);
    // }

    console.log(cleanValue.includes('.'));

    console.log(cleanValue.charAt(cleanValue.length - 1));
    
    

    if (cleanValue.includes('.') && cleanValue.lastIndexOf('.') != dotIndex && cleanValue.charAt(cleanValue.length - 1) == '.') {
      console.log(dotIndex);
      
      // if (cleanValue)
      // const dotIndex = cleanValue.indexOf('.');
      // cleanValue = cleanValue.replace(cleanValue.charAt(cleanValue.length - 1), '')
      // event.preventDefault()
      this.el.nativeElement.value = cleanValue.slice(0, -1);
      return;
    }

    // if (cleanValue.includes('.') && inputValue.endsWith('.')) {
    //   // If input already contains a dot and the input ends with a dot, prevent input
    //   this.el.nativeElement.value = cleanValue.slice(0, -1);
    //   return;
    // }

    console.log(inputValue);
    console.log(cleanValue);

    this.el.nativeElement.value = cleanValue
    
  }

}
