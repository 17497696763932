import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { LoginService } from "../services/login.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard {
  constructor(public router: Router, private toastr: ToastrService, private loginService: LoginService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Guard for user is login or not
    let user = JSON.parse(localStorage.getItem("user")!);
    let token = JSON.parse(localStorage.getItem("user")!)?.access_token;
    console.log(next);
    console.log(state);
    this.handleSurveyUnauthorized(state);
    if (!state.url.includes('login') && (!user || user === null)) {
      this.toastr.error("يجب تسجيل الدخول اولاً")
      this.router.navigate(["/auth/login-as"]);
      return true;
    } else if (user) {
      if (!Object.keys(user).length) {
        this.router.navigate(["/auth/login-as"]);
        return true;
      }
    } else if (this.loginService.isTokenExpired()) {
      this.toastr.error("Token is expired")
      this.router.navigate(["/auth/login-as"]);
      return true;
    }
    return true;
  }

  handleSurveyUnauthorized(state: RouterStateSnapshot) {
    if (state.url.includes('questionnaires/feedback')) {
      localStorage.setItem('cached_survey_url', JSON.stringify(window.location.href));
    }
  }
}

