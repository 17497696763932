import { Component, Injector, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AppComponentBase } from 'src/app/shared/app-component-base';
import { OrganizationList } from 'src/app/shared/models/organization/organization';
import { LoginData, User } from 'src/app/shared/models/user';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { Menu, NavService } from 'src/app/shared/services/nav.service';
import { OrganizationSetupService } from 'src/app/shared/services/organization/organization-setup.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends AppComponentBase implements OnInit {

  public newUser = false;
  // public user: firebase.User;
  public loginForm!: FormGroup;
  public show: boolean = false
  public errorMessage: any;
  formValues!: LoginData
  organizations!: OrganizationList

  listOfErrors: any[] = []

  public selectedLanguage: any = {
    language: localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')!).language : 'Arabic',
    code: localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')!).code : 'ar',
    type: localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')!).type : 'AR',
    icon: localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')!).icon : 'eg'
  }

  public layoutType: string = "ltr";
  public menuItems!: Menu[];

  public languages: any[] = [
    {
      language: 'English',
      code: 'en',
      type: 'US',
      icon: 'us'
    },
    {
      language: 'Arabic',
      code: 'ar',
      icon: 'sa'
    },
  ]

  constructor(
    injector: Injector,
    private fb: FormBuilder, 
    public translate: TranslateService, 
    public router: Router,
    private loginService: LoginService,
    private toastr: ToastrService,
    public layout: LayoutService,
    private organizationService: OrganizationSetupService, 
    private navServices: NavService,
    private route: ActivatedRoute
    ) {
      super(injector)
      this.route.queryParams.subscribe((params) => {
        this.layout.config.settings.layout = params['layout'] ? params['layout'] : this.layout.config.settings.layout
      })
    }

  ngOnInit() {
    this.createForm()
  }

  changeLanguage(lang: any) {
    console.log(this.languages);
    
    this.translate.use(lang)
    this.selectedLanguage = lang;
    localStorage.setItem('currentLang', lang);
    if (lang == 'ar') localStorage.setItem('language', JSON.stringify(this.languages[1]));
    if (lang == 'en') localStorage.setItem('language', JSON.stringify(this.languages[0]));
    // window.location.reload()
  }

  customizeLayoutType(val: any) {
    this.layoutType = val;
    this.layout.config.settings.layout_type = val;
    if (val == "rtl") {
      document.getElementsByTagName("html")[0].setAttribute("dir", val);
      document.body.className = "rtl";
    } else if (val == "box-layout") {
      document.getElementsByTagName("html")[0].setAttribute("dir", val);
      document.body.className = "box-layout";
    } else {
      document.getElementsByTagName("html")[0].removeAttribute("dir");
      document.body.className = "";
    }
  }

  getOrganizations() {
    this.organizationService.getOrganizations().subscribe((organizations: OrganizationList) => {
      this.organizations = organizations
      
    })
  }

  createForm() {
    this.loginForm = this.fb.group({
      user_name: ["", [Validators.required]],
      password: ["", Validators.required]
    });
  }

  get f() {
    return this.loginForm.controls
  }

  getIndexOfError(id: any) {
    return this.listOfErrors.findIndex(item => item.id == id)
  }

  pushToErrorList(item: any) {
    if (this.getIndexOfError(item.id) == -1) {
      this.listOfErrors.push(item)
    }
  }

  removeFromErrorList(id: any) {
    if (this.getIndexOfError(id) != -1) {
      this.listOfErrors.splice(this.getIndexOfError(id), 1)
    }
  }

  validateForm() {
    const emailRegex: any = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!this.formValues.user_name || !this.formValues.password) {
      let item = {id: 1, message: 'تأكد من جميع البيانات المطلوبة'}
      this.pushToErrorList(item)
    } else {
      this.removeFromErrorList(1)
    }

    if (!emailRegex.test(this.formValues.user_name)) {
      if ( isNaN(Number(this.formValues.user_name)) ) {
        let item = {id: 2, message: 'البريد الالكتروني أو رقم الهاتف غير صحيح'}
        this.pushToErrorList(item)
      } else if (this.formValues.user_name.length != 9) {
        let item = {id: 2, message: 'رقم الهاتف غير صحيح'}
        this.pushToErrorList(item)
      } else {
        this.removeFromErrorList(2)
      }
    } else {
      this.removeFromErrorList(2)
    }

    if (this.loginForm.invalid) {
      let item = {id: 3, message: 'هناك خطأ ما,, تأكد من جميع البيانات'}
      this.pushToErrorList(item)
    } else {
      this.removeFromErrorList(3)
    }

    if (this.listOfErrors.length > 0) {
      this.formValid = false
    } else {
      this.formValid = true
    }

    return this.formValid
  }

  loginFormData(values: any) {
    let formData = new FormData();
    Object.entries(values).forEach(([key, value]: any) => {
      formData.append(key, value);
    })

    return formData
  }

  login() {
    this.formSubmitted = true
    this.formValues = this.loginForm.value as LoginData

    if (this.validateForm()) {

      let userModelToSend = {
        user_name: this.formValues.user_name,
        password: this.formValues.password
      } as LoginData

      let model = this.loginFormData(userModelToSend)
      this.loginService.login(model).subscribe(res => {
        console.log(`res`, res);
        
        // window.location.reload()
        // this.organizationService.getOrganizations().subscribe((organizations: OrganizationList) => {
        //   this.organizations = organizations
        //   this.navServices.items.subscribe((menuItems: any) => {
        //     console.log(menuItems);
            
        //     this.menuItems = menuItems.filter((menuItem: any) => menuItem.havePermissionToShow);
        //     console.log(this.menuItems);
        //     if (this.organizations.data.length == 0) {
        //       this.router.navigate(["/setup"]);
        //     } else {
        //       if (this.menuItems[0].type == 'link') {
        //         this.router.navigate([this.menuItems[0].path])
        //       }
        //       if (this.menuItems[0].type == 'sub') {
        //         this.router.navigate([this.menuItems[0]?.children![0].path])
        //       }
        //     }
            
        //   })

        //   // this.router.navigate(['/dashboard/default'])
        // })
      }, err => {
        console.log(err);
        
        // this.toastr.error('حدث خطأ ما')
        this.toastr.error(err.error.message)
      })

    } else {
      this.listOfErrors.reverse().forEach(element => {
        this._toastr.error(element.message)
      });
    }
  }

  showPassword(){
    this.show = !this.show
  }

}
