import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[percentageValidation]'
})
export class PercentageValidationDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInput(event: string) {
    const inputValue = this.el.nativeElement.value;
    if (parseInt(inputValue) > 100) {
      this.el.nativeElement.value = '100';
    }
  }

}
