import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Menu, NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent {
  public iconSidebar: any;
  public menuItems!: Menu[];

  // For Horizontal Menu
  public margin: any = 0;
  public width: any = window.innerWidth;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = false;

  homeLink!: any;

  constructor(
    private router: Router,
    public navServices: NavService,
    public layout: LayoutService,
    public translate: TranslateService
  ) {
    this.navServices.items.subscribe((menuItems: any) => {
      // this.menuItems = menuItems.filter((menuItem: any) => menuItem.havePermissionToShow);
      if (!this.navServices.userPermissions) window.location.reload();
      this.menuItems = this.navServices.processMenuItems();
      // console.log(this.menuItems);

      if (this.menuItems[0].type == 'link') {
        // this.router.navigate([this.menuItems[0].path])
        this.homeLink = this.menuItems[0].path;
      }
      if (this.menuItems[0].type == 'sub') {
        // this.router.navigate([this.menuItems[0]?.children![0].path])
        this.homeLink = this.menuItems[0]?.children![0].path;
      }
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter((items: any) => {
            if (items.path === event.url) {
              this.setNavActive(items);
            }
            if (!items.children) {
              return false;
            }
            items.children.filter((subItems: any) => {
              if (subItems.path === event.url) {
                this.setNavActive(subItems);
              }
              if (!subItems.children) {
                return false;
              }
              subItems.children.filter((subSubItems: any) => {
                if (subSubItems.path === event.url) {
                  this.setNavActive(subSubItems);
                }
              });
            });
          });
        }
      });
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.width = event.target.innerWidth - 500;
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    let sidebar = document.querySelector('.sidebar-wrapper');
    var sidebarContainer = sidebar?.closest('.sidebar-container');
    let el = sidebarContainer?.nextElementSibling as HTMLElement;
    if (this.navServices.collapseSidebar) {
      if (this.translate.currentLang == 'ar') {
        el.style.marginRight = '120px';
        el.style.left = '0px';
      } else {
        el.style.marginLeft = '120px';
        el.style.marginRight = '0px';
      }
    } else {
      if (this.translate.currentLang == 'ar') {
        el.style.marginRight = '296px';
        el.style.marginLeft = '0px';
      } else {
        el.style.marginLeft = '296px';
        el.style.marginRight = '0px';
      }
    }
  }

  // Active Nave state
  setNavActive(item: any) {
    this.menuItems.filter((menuItem) => {
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  havePermissionToShow(item: any) {
    console.log(item);
    this.menuItems.filter((menuItem) => menuItem.havePermissionToShow);
    console.log(this.menuItems);
  }

  // Click Toggle menu
  toggletNavActive(item: any) {
    if (!item.active) {
      this.menuItems.forEach((a) => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) {
          return false;
        }
        a.children.forEach((b) => {
          if (a.children?.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }

  // For Horizontal Menu
  scrollToLeft() {
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.leftArrowNone = true;
      this.rightArrowNone = false;
    } else {
      this.margin += this.width;
      this.rightArrowNone = false;
    }
  }

  scrollToRight() {
    if (this.margin <= -3051) {
      this.margin = -3464;
      this.leftArrowNone = false;
      this.rightArrowNone = true;
    } else {
      this.margin += -this.width;
      this.leftArrowNone = false;
    }
  }
}
