import { Component, Injector, Input, OnInit } from '@angular/core';
import { AppComponentBase } from 'src/app/shared/app-component-base';

@Component({
  selector: 'app-basic-area-chart',
  templateUrl: './basic-area-chart.component.html',
  styleUrls: ['./basic-area-chart.component.scss']
})
export class BasicAreaChartComponent extends AppComponentBase implements OnInit {

  chartOptions: any
  @Input() series: any
  @Input() categories: any
  @Input() title: any

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.chartOptions = {
      series: this.series,
      chart: {
        type: "area",
        height: 350,
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false,
        style: {
          // fontFamily: ["cairo"],
        }
      },
      stroke: {
        width: 3,
        curve: "smooth",
        lineCap: "square",
        colors: ['#7366ff'],
      },
      title: {
        text: this.title,
        align: "center",
        style: {
          // fontFamily: ["cairo"],
        }
      },
      // subtitle: {
      //   text: "Price Movements",
      //   align: "left"
      // },
      labels: this.categories,
      xaxis: {
        labels: {
          style: {
            // fontFamily: ["cairo"],
          }
        }
      },
      yaxis: {
        opposite: true
      },
      legend: {
        horizontalAlign: "left"
      }
    };
  }

}
