import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-paying-status',
  templateUrl: './paying-status.component.html',
  styleUrls: ['./paying-status.component.scss']
})
export class PayingStatusComponent {

  @Input() payingStatus: any
  @Input() totalAmount: number | undefined = 0

}
