import { Component, Injector, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AppComponentBase } from 'src/app/shared/app-component-base';
import { OrganizationList } from 'src/app/shared/models/organization/organization';
import { LoginData, ResetPassword } from 'src/app/shared/models/user';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { Menu, NavService } from 'src/app/shared/services/nav.service';
import { OrganizationSetupService } from 'src/app/shared/services/organization/organization-setup.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent extends AppComponentBase implements OnInit {

  // public newUser = false;
  // public user: firebase.User;
  public resetPasswordForm!: FormGroup;
  public show: boolean = false
  // public errorMessage: any;
  formValues!: ResetPassword

  listOfErrors: any[] = []

  codeFirstDigit!: string
  codeSecondDigit!: string
  codeThirdDigit!: string
  codeFourthDigit!: string

  codeSent: boolean = false
  codeVerified: boolean = false

  public selectedLanguage: any = {
    language: localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')!).language : 'Arabic',
    code: localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')!).code : 'ar',
    type: localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')!).type : 'AR',
    icon: localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')!).icon : 'eg'
  }

  public layoutType: string = "ltr";
  public menuItems!: Menu[];

  public languages: any[] = [
    {
      language: 'English',
      code: 'en',
      type: 'US',
      icon: 'us'
    },
    {
      language: 'Arabic',
      code: 'ar',
      icon: 'sa'
    },
  ]

  constructor(
    injector: Injector,
    private fb: FormBuilder, 
    public translate: TranslateService, 
    public router: Router,
    private loginService: LoginService,
    private toastr: ToastrService,
    public layout: LayoutService,
    private organizationService: OrganizationSetupService, 
    private navServices: NavService,
    private route: ActivatedRoute
    ) {
      super(injector)
      this.route.queryParams.subscribe((params) => {
        this.layout.config.settings.layout = params['layout'] ? params['layout'] : this.layout.config.settings.layout
      })
    }

  ngOnInit() {
    this.createForm()
  }

  changeLanguage(lang: any) {
    console.log(this.languages);
    
    this.translate.use(lang)
    this.selectedLanguage = lang;
    localStorage.setItem('currentLang', lang);
    if (lang == 'ar') localStorage.setItem('language', JSON.stringify(this.languages[1]));
    if (lang == 'en') localStorage.setItem('language', JSON.stringify(this.languages[0]));
    // window.location.reload()
  }

  customizeLayoutType(val: any) {
    this.layoutType = val;
    this.layout.config.settings.layout_type = val;
    if (val == "rtl") {
      document.getElementsByTagName("html")[0].setAttribute("dir", val);
      document.body.className = "rtl";
    } else if (val == "box-layout") {
      document.getElementsByTagName("html")[0].setAttribute("dir", val);
      document.body.className = "box-layout";
    } else {
      document.getElementsByTagName("html")[0].removeAttribute("dir");
      document.body.className = "";
    }
  }

  createForm() {
    this.resetPasswordForm = this.fb.group({
      phone: ["", [Validators.required]],
      code: ["", Validators.required],
      password: ['', [Validators.required, Validators.minLength(8)]],
      password_confirmation: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  get f() {
    return this.resetPasswordForm.controls
  }

  sendCode() {
    this.codeSent = false
    if (!this.resetPasswordForm.get('phone')?.value) {
      this.toastr.error('رقم الهاتف مطلوب')
    } else if (this.resetPasswordForm.get('phone')?.value.length != 9) {
      this.toastr.error('رقم الهاتف غير صحيح')
    } else {
      this.loginService.resetCode(this.resetPasswordForm.get('phone')?.value).subscribe((message: any) => {
        this.toastr.success(message.message)
        console.log(message);
        this.codeSent = message.code == 200 ? true : false
      }, err => {
        this.showErrors(err)
      })
    }
  }

  focusOnSecond(event: any) {
    event.target.parentElement.nextElementSibling.children[0].focus()
  }

  verifyCode() {
    console.log(this.codeFirstDigit, 'first');
    console.log(this.codeSecondDigit, 'second');
    console.log(this.codeThirdDigit, 'third');
    console.log(this.codeFourthDigit, 'fourth');

    let code = this.codeFirstDigit + this.codeSecondDigit + this.codeThirdDigit + this.codeFourthDigit
    
    console.log(code);

    this.resetPasswordForm.get('code')?.patchValue(code)

    if (code == '1234') { 
      this.codeVerified = true 
    } else {
      this.codeVerified = false
      this.toastr.error('رمز التحقق غير صحيح')
    }
  }

  getIndexOfError(id: any) {
    return this.listOfErrors.findIndex(item => item.id == id)
  }

  pushToErrorList(item: any) {
    if (this.getIndexOfError(item.id) == -1) {
      this.listOfErrors.push(item)
    }
  }

  removeFromErrorList(id: any) {
    if (this.getIndexOfError(id) != -1) {
      this.listOfErrors.splice(this.getIndexOfError(id), 1)
    }
  }

  validateForm() {

    if (
      !this.formValues.phone || 
      !this.formValues.code ||
      !this.formValues.password ||
      !this.formValues.password_confirmation
    ) {
      let item = {id: 1, message: 'تأكد من جميع البيانات المطلوبة'}
      this.pushToErrorList(item)
    } else {
      this.removeFromErrorList(1)
    }

    if (this.formValues.password && this.formValues.password.length < 8) {
      let item = {id: 2, message: 'كلمة المرور اقل من 8 عناصر'}
      this.pushToErrorList(item)
    } else {
      this.removeFromErrorList(2)
    }

    if (this.formValues.password !== this.formValues.password_confirmation) {
      let item = {id: 3, message: 'كلمة المرور غير متطابقة'}
      this.pushToErrorList(item)
    } else {
      this.removeFromErrorList(3)
    }

    if (this.listOfErrors.length > 0) {
      this.formValid = false
    } else {
      this.formValid = true
    }

    return this.formValid
  }

  resetPasswordFormData(values: any) {
    let formData = new FormData();
    Object.entries(values).forEach(([key, value]: any) => {
      formData.append(key, value);
    })

    return formData
  }

  resetPassword() {
    this.formSubmitted = true
    this.formValues = this.resetPasswordForm.value as ResetPassword

    let dataToSend = {
      phone: this.formValues.phone,
      code: this.formValues.code,
      password: this.formValues.password,
      password_confirmation: this.formValues.password_confirmation,
    } as ResetPassword

    let model = this.resetPasswordFormData(dataToSend)

    console.log(dataToSend);
    

    if (this.validateForm()) {

      this.loginService.resetPassword(model).subscribe((res: any) => {
        console.log(`res`, res);
        this._toastr.success(res.message);
        this.router.navigate(['/auth/login'])
      }, err => {
        console.log(err);
        
        // this.toastr.error('حدث خطأ ما')
        this.toastr.error(err.error.message)
      })

    } else {
      this.listOfErrors.reverse().forEach(element => {
        this._toastr.error(element.message)
      });
    }
  }

  showPassword(){
    this.show = !this.show
  }

}
