import { AngularEditorConfig } from "@kolkov/angular-editor";
import { ParameterClass } from "../helpers/helper.service";

export class filterationParams {

  notPaginationParams: ParameterClass[];

  constructor() {
    this.notPaginationParams = [ 
      new ParameterClass('paginate', 'false') 
    ]
  }
}

export class AngularEditor {
  editorConfig: AngularEditorConfig

  constructor() {
    this.editorConfig = {
      editable: true,
      spellcheck: true,
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
        {
          name: 'quote',
          class: 'quote',
        },
        {
          name: 'redText',
          class: 'redText'
        },
        {
          name: 'titleText',
          class: 'titleText',
          tag: 'h1',
        },
      ],
      // uploadUrl: 'v1/image',
      // upload: (file: File) => { ... },
      // uploadWithCredentials: false,
      // sanitize: true,
      // toolbarPosition: 'top',
      // toolbarHiddenButtons: [
      //   ['bold', 'italic'],
      //   ['fontSize']
      // ]
    };
  }
}