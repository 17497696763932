import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { OrganizationList } from '../models/organization/organization';
import { HttpClient } from '@angular/common/http';
import { OrganizationSetupService } from '../services/organization/organization-setup.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: "root",
})
export class SetupGuard  {
  organizations!: OrganizationList
  constructor(public router: Router, private toastr: ToastrService, 
    private organizationService: OrganizationSetupService, ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.organizationService.getOrganizations().subscribe((organizations: OrganizationList) => {
      this.organizations = organizations
      if (this.organizations?.data.length > 0) {
        this.toastr.error("غير مسموح لك بدخول هذه الصفحة")
        this.router.navigate(["/dashboard"]);
        return false;
      } else {
        return true;
      }
    })
    return true
  }
}
