import {
  Component,
  Injector,
  Input,
  Output,
  EventEmitter,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { AppComponentBase } from '../../app-component-base';

@Component({
  selector: 'app-shared-dropdown',
  templateUrl: './shared-dropdown.component.html',
  styleUrls: ['./shared-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SharedDropdownComponent),
      multi: true,
    },
  ],
})
export class SharedDropdownComponent
  extends AppComponentBase
  implements ControlValueAccessor
{
  @Input() options: any[] = [];
  @Input() label: string = '';
  @Input() formControlName: string = '';
  @Input() optionLabel: string = '';
  @Output() selectionChange = new EventEmitter<any>(); // Emit selected option
  @Output() clearSelection = new EventEmitter<void>(); // Emit clear action
  @Input() showClearButton: boolean = false;
  @Input() showSearchBar: boolean = false;

  selectedOption: any;
  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    // Set the first option as selected by default if options are provided
    if (this.options && this.options.length > 0) {
      this.selectedOption = this.options[0];
      this.selectionChange.emit(this.selectedOption);
    }
  }

  writeValue(value: any): void {
    this.selectedOption = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Handle the disabled state if needed
  }

  onSelectionChange(event: any) {
    this.selectedOption = event.value;
    this.onChange(event.value);
    this.selectionChange.emit(event.value);
    this.onTouched();
  }

  onClear() {
    this.selectedOption = null;
    this.onChange(null);
    this.clearSelection.emit();
  }
}
