import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ContentComponent } from './components/layout/content/content.component';
import { FullComponent } from './components/layout/full/full.component';
import { ClickOutSideDirective } from './directives/click-out-side.directive';
import { DisableKeyPressDirective } from './directives/disable-key-press.directive';
import { OnlyAlphabetsDirective } from './directives/only-alphabets.directive';
import { OnlyEnglishAlphabetsDirective } from './directives/only-english-alphabets.directive';
import { OnlyArabicAlphabetsDirective } from './directives/only-arabic-alphabets.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { ShortingDirective } from './directives/shorting.directive';
import { ShowOptionsDirective } from './directives/show-options.directive';
import { NgbdSortableHeaderDirective } from './directives/ngbd-sortable-header.directive';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { DragulaModule } from "ng2-dragula";
import { TranslateModule } from '@ngx-translate/core';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { LayoutService } from './services/layout.service';
import { NavService } from './services/nav.service';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { SearchComponent } from './components/header/elements/search/search.component';
import { LanguagesComponent } from './components/header/elements/languages/languages.component';
import { NotificationComponent } from './components/header/elements/notification/notification.component';
import { MyAccountComponent } from './components/header/elements/my-account/my-account.component';
import { SvgIconComponent } from './components/svg-icon/svg-icon.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestHeadersInterceptor } from './interceptors/request-headers.interceptor';
import { InputErrorComponent } from './components/input-error/input-error.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { OrganizationChartModule } from 'primeng/organizationchart';
import {
  DiagramModule,
  SnappingService,
  HierarchicalTreeService,
  DataBindingService,
} from '@syncfusion/ej2-angular-diagrams';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { ToolbarModule } from 'primeng/toolbar';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MaxDigitDirective } from './directives/max-digit.directive';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NoLeadingZeroDirective } from './directives/no-leading-zero.directive';
import { StartPhoneFiveDirective } from './directives/start-phone-five.directive';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { PaginatorModule } from 'primeng/paginator';
import { OnlyAlphabetsNumbersDirective } from './directives/only-alphabets-numbers.directive';
import { ChartBoxComponent } from './components/dahsboard/chart-box/chart-box.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ChartistModule } from 'ng-chartist';
import { NgChartsModule } from 'ng2-charts';
import { PayingStatusComponent } from './components/dahsboard/paying-status/paying-status.component';
import { TreeTableModule } from 'primeng/treetable';
import { PercentageValidationDirective } from './directives/percentage-validation.directive';
import { SliderModule } from 'primeng/slider';
import { RatingModule } from 'primeng/rating';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { InputTextModule } from 'primeng/inputtext';
import { TestInterceptor } from './interceptors/test.interceptor';
import { ColumnChartWithDataLabelsComponent } from './components/dahsboard/column-chart-with-data-labels/column-chart-with-data-labels.component';
import { SimplePieChartComponent } from './components/dahsboard/simple-pie-chart/simple-pie-chart.component';
import { CustomDataLabelsBarChartComponent } from './components/dahsboard/custom-data-labels-bar-chart/custom-data-labels-bar-chart.component';
import { DistributedTreemapComponent } from './components/dahsboard/distributed-treemap/distributed-treemap.component';
import { BasicLineChartComponent } from './components/dahsboard/basic-line-chart/basic-line-chart.component';
import { BasicAreaChartComponent } from './components/dahsboard/basic-area-chart/basic-area-chart.component';
import { DecimalInputDirective } from './directives/decimal-input.directive';
import { PreventPasteDirective } from './directives/prevent-paste.directive';
import { GrowthAreaChartComponent } from './components/dahsboard/growth-area-chart/growth-area-chart.component';
import { MaxValueDirective } from './directives/max-value.directive';
import { SharedDropdownComponent } from './components/shared-dropdown/shared-dropdown.component';
import { ExportAsModule } from 'ngx-export-as';
import { MultiSelectModule } from 'primeng/multiselect';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { GoogleMapsModule } from '@angular/google-maps';
import { MapComponent } from './components/map/map.component';
import { HorizontalLineChartComponent } from './components/dahsboard/horizontal-line-chart/horizontal-line-chart.component';
import { CardComponent } from './components/dahsboard/app-card/card.component';
import { VerticalBarChartComponent } from './components/dahsboard/vertical-bar-chart/vertical-bar-chart.component';
import { RemoveCountryCodeDirective } from './directives/remove-country-code.directive';
import { DataTableComponent } from './components/data-table/data-table.component';
import { ReceiptComponent } from '../components/billing/receipt/receipt.component';
import { CreditInputDirective } from './directives/credit-input.directive';
@NgModule({
  declarations: [
    BreadcrumbComponent,
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    SidebarComponent,
    ContentComponent,
    FullComponent,
    FeatherIconsComponent,
    SearchComponent,
    // MegaMenuComponent,
    LanguagesComponent,
    NotificationComponent,
    MyAccountComponent,
    SvgIconComponent,
    ColumnChartWithDataLabelsComponent,
    ClickOutSideDirective,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyEnglishAlphabetsDirective,
    OnlyArabicAlphabetsDirective,
    OnlyNumbersDirective,
    ShortingDirective,
    ShowOptionsDirective,
    NgbdSortableHeaderDirective,
    InputErrorComponent,
    MaxDigitDirective,
    NoLeadingZeroDirective,
    StartPhoneFiveDirective,
    OnlyAlphabetsNumbersDirective,
    ChartBoxComponent,
    PayingStatusComponent,
    PercentageValidationDirective,
    SimplePieChartComponent,
    CustomDataLabelsBarChartComponent,
    DistributedTreemapComponent,
    BasicLineChartComponent,
    BasicAreaChartComponent,
    DecimalInputDirective,
    PreventPasteDirective,
    GrowthAreaChartComponent,
    MaxValueDirective,
    SharedDropdownComponent,
    MapComponent,
    HorizontalLineChartComponent,
    VerticalBarChartComponent,
    CardComponent,
    RemoveCountryCodeDirective,
    DataTableComponent,
    ReceiptComponent,
    CreditInputDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule,
    CarouselModule,
    BsDatepickerModule,
    TimepickerModule,
    OrganizationChartModule,
    DiagramModule,
    ModalModule.forRoot(),
    NgSelectModule,
    AngularEditorModule,
    TableModule,
    ButtonModule,
    TooltipModule,
    ToolbarModule,
    DialogModule,
    NgxMaterialTimepickerModule,
    CollapseModule,
    CalendarModule,
    DropdownModule,
    PaginatorModule,
    NgApexchartsModule,
    NgChartsModule,
    ChartistModule,
    TreeTableModule,
    SliderModule,
    ExportAsModule,
    MultiSelectModule,
    GoogleMapsModule,
    MatButtonModule,
    InputTextModule,
    AutoCompleteModule,
  ],
  providers: [
    NavService,
    LayoutService,
    DecimalPipe,
    DatePipe,
    HierarchicalTreeService,
    DataBindingService,
    SnappingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestHeadersInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
  ],
  exports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    BsDatepickerModule,
    TimepickerModule,
    LoaderComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    SvgIconComponent,
    InputErrorComponent,
    ChartBoxComponent,
    PayingStatusComponent,
    ColumnChartWithDataLabelsComponent,
    SimplePieChartComponent,
    CustomDataLabelsBarChartComponent,
    DistributedTreemapComponent,
    BasicLineChartComponent,
    BasicAreaChartComponent,
    GrowthAreaChartComponent,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyEnglishAlphabetsDirective,
    OnlyArabicAlphabetsDirective,
    OnlyNumbersDirective,
    MaxDigitDirective,
    NoLeadingZeroDirective,
    StartPhoneFiveDirective,
    OnlyAlphabetsNumbersDirective,
    PercentageValidationDirective,
    DecimalInputDirective,
    PreventPasteDirective,
    MaxValueDirective,
    NgxSpinnerModule,
    OrganizationChartModule,
    DiagramModule,
    ModalModule,
    NgSelectModule,
    AngularEditorModule,
    TableModule,
    ButtonModule,
    ToolbarModule,
    DialogModule,
    TooltipModule,
    NgxMaterialTimepickerModule,
    CollapseModule,
    CalendarModule,
    DropdownModule,
    PaginatorModule,
    NgApexchartsModule,
    TreeTableModule,
    SliderModule,
    SharedDropdownComponent,
    MapComponent,
    ExportAsModule,
    MultiSelectModule,
    HorizontalLineChartComponent,
    VerticalBarChartComponent,
    CardComponent,
    RemoveCountryCodeDirective,
    RatingModule,
    InputTextModule,
    AutoCompleteModule,
    DataTableComponent,
    ReceiptComponent,
    CreditInputDirective
  ],
})
export class SharedModule {}
