<div class="card">
  <!-- <div class="card-header">
    <h5>خطط السداد</h5>
    <span>مجموع المدفوعات {{totalAmount}}</span>
  </div> -->
  <div class="card-body">
    <div class="chart-container skill-chart">
      <div id="circlechart">
        <apx-chart
          [chart]="payingStatus.chart"
          [plotOptions]="payingStatus.plotOptions"
          [series]="payingStatus.series"
          [labels]="payingStatus.labels"
          [legend]="payingStatus.legend"
          [colors]="payingStatus.colors"
          [responsive]="payingStatus.responsive"
        ></apx-chart>
      </div>
    </div>
  </div>
</div>
