import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[MaxDigit]'
})
export class MaxDigitDirective {

  @Input() MaxDigit!: number;

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event']) onInput(event: KeyboardEvent) {

    const value = (event.target as HTMLInputElement).value;
    const numericValue = parseInt(value + event.key, 10);
    
    // if ((!isNaN(numericValue) && numericValue >= Math.pow(10, this.MaxDigit))) {
    //   event.preventDefault();
    // }
    
    
    if ((event.key === 'Backspace' || event.key === 'Delete') || (numericValue < Math.pow(10, this.MaxDigit))) {
      return; // Allow the action
    }
      event.preventDefault();
  }

}
