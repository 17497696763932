import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-vertical-bar-chart',
  templateUrl: './vertical-bar-chart.component.html',
  styleUrls: ['./vertical-bar-chart.component.scss'],
})
export class VerticalBarChartComponent implements OnChanges {
  @Input() chartData!: any;

  public chartConfig: any;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['chartData']) {
      console.log('chartData changed:', this.chartData);
      this.generateChartConfig();
    }
  }

  generateChartConfig() {
    if (
      this.chartData &&
      Array.isArray(this.chartData) &&
      this.chartData.length > 0
    ) {
      this.chartConfig = this.chartData[0]; // Since we passed an array with a single element
      console.log('Generated chartConfig:', this.chartConfig);
    } else {
      console.error('Invalid chartData:', this.chartData);
    }
  }
}
