import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[removeCountryCode]'
})
export class RemoveCountryCodeDirective {

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    let input = this.el.nativeElement.value;
    if (input.startsWith('966')) {
      this.el.nativeElement.value = input.substring(3);
    }
  }

}
