import { Component, Injector, Input, OnInit } from '@angular/core';
import { AppComponentBase } from 'src/app/shared/app-component-base';

@Component({
  selector: 'app-basic-line-chart',
  templateUrl: './basic-line-chart.component.html',
  styleUrls: ['./basic-line-chart.component.scss']
})
export class BasicLineChartComponent extends AppComponentBase implements OnInit {

  chartOptions: any
  @Input() series: any
  @Input() title: any
  @Input() categories: any

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.chartOptions = {
      series: this.series,
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight"
      },
      title: {
        text: this.title,
        align: "center",
        style: {
          // fontFamily: ["cairo"],
        }
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      xaxis: {
        categories: this.categories,
        labels: {
          style: {
            // fontFamily: ["cairo"],
          }
        }
      }
    };
  }

}
