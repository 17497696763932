import { Component, Injector, Input, OnInit } from '@angular/core';
import { AppComponentBase } from 'src/app/shared/app-component-base';

@Component({
  selector: 'app-simple-pie-chart',
  templateUrl: './simple-pie-chart.component.html',
  styleUrls: ['./simple-pie-chart.component.scss']
})
export class SimplePieChartComponent extends AppComponentBase implements OnInit {

  chartOptions: any
  @Input() series: any
  @Input() labels: any

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    console.log(this.series);
    
    this.chartOptions = {
      series: this.series,
      chart: {
        type: "donut",
        // fontFamily: ["cairo"]
      },
      labels: this.labels,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  }

}
