import { HostListener, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class InactivityService {

  private inactivityTimeout: any;
  public inactivityTime: number = 10
  public inactivityPeriod = this.inactivityTime * 60; // 5 minutes in milliseconds

  constructor(private router: Router, private authService: LoginService) {
  }

  resetTimer() {
    this.inactivityTimeout = setInterval(() => {
      this.inactivityPeriod--
      if (this.inactivityPeriod == 0) {
        this.authService.Logout()
        this.router.navigate(['/auth/login-as']);
        clearInterval(this.inactivityTimeout)
      }
    }, 1000)
  }
}
