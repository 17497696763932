export enum Roles {
  //home
  Home = 'home',
  ShowHome = 'show_home',
  //roles
  Roles = 'roles',
  ListRoles = 'list_roles',
  ShowRole = 'show_role',
  CreateRole = 'create_role',
  UpdateRole = 'update_role',
  //users
  Users = 'users',
  ListUsers = 'list_users',
  ShowUser = 'show_user',
  CreateUsers = 'create_user',
  UpdateUser = 'update_user',
  //notifications
  Notifications = 'notifications',
  ListNotifications = 'list_notifications',
  ShowNotification = 'show_notification',
  CreateNotification = 'create_notification',
  UpdateNotification = 'update_notification',
  NotificationTexts = 'notification_texts',
  ListNotificationTexts = 'list_notification_texts',
  ShowNotificationText = 'show_notification_text',
  CreateNotificationText = 'create_notification_text',
  UpdateNotificationText = 'update_notification_text',

  // e_invoice
  eInvoice = 'e_invoice',
  eInvoiceCountryStates = 'e_invoice_country_states',
  eInvoiceCompanyGet = 'e_invoice_company_get',
  eInvoiceCompanyUpdate = 'e_invoice_company_update',
  eInvoiceCreateInvoice = 'e_invoice_create_invoice',


  //ListQuestionnaires
  Questionnaires = 'questionnaires',
  ListQuestionnaires = 'list_questionnaires',
  ShowQuestionnaire = 'show_questionnaire',
  CreateQuestionnaire = 'create_questionnaire',
  UpdateQuestionnaire = 'update_questionnaire',

  //settings school hubs
  SchoolHubs = 'school-hubs',
  ListSchoolHubs = 'list_school-hubs',
  ShowSchoolHub = 'show_school-hub',
  CreateSchoolHub = 'create_school-hub',
  UpdateSchoolHub = 'update_school-hub',
  //settings genders
  Genders = 'genders',
  ListGenders = 'list_genders',
  ShowGender = 'show_gender',
  CreateGender = 'create_gender',
  UpdateGender = 'update_gender',
  //settings branches
  Branches = 'branches',
  ListBranches = 'list_branches',
  ShowBranch = 'show_branch',
  CreateBranch = 'create_branch',
  UpdateBranch = 'update_branch',
  //settings schools
  Schools = 'schools',
  ListSchools = 'list_schools',
  ShowSchool = 'show_school',
  CreateSchool = 'create_school',
  UpdateSchool = 'update_school',
  //settings group degrees
  GroupDegrees = 'group-degrees',
  ListGroupDegrees = 'list_group-degrees',
  ShowGroupDegree = 'show_group-degree',
  CreateGroupDegree = 'create_group-degree',
  UpdateGroupDegree = 'update_group-degree',
  //settings degrees
  Degrees = 'degrees',
  ListDegrees = 'list_degrees',
  ShowDegree = 'show_degree',
  CreateDegree = 'create_degree',
  UpdateDegree = 'update_degree',
  //settings company
  Companies = 'companies',
  ListCompanies = 'list_Companies',
  ShowCompany = 'show_company',
  CreateCompany = 'create_company',
  UpdateCompany = 'update_company',
  //HR departments
  Departments = 'departments',
  ListDepartments = 'list_departments',
  ShowDepartment = 'show_department',
  CreateDepartment = 'create_department',
  UpdateDepartment = 'update_department',
  //HR jobs
  Jobs = 'jobs',
  ListJobs = 'list_jobs',
  ShowJob = 'show_job',
  CreateJob = 'create_job',
  UpdateJob = 'update_job',
  //HR universities
  Universities = 'universities',
  ListUniversities = 'list_universities',
  ShowUniversity = 'show_university',
  CreateUniversity = 'create_university',
  UpdateUniversity = 'update_university',
  //HR staffs
  Contracts = 'contracts',
  ListContracts = 'list_contracts',
  ShowContract = 'show_contract',
  CreateContract = 'create_contract',
  UpdateContract = 'update_contract',
  DeleteContract = 'delete_contract',
  //HR staffs
  Staff = 'staff',
  ListStaffs = 'list_staff',
  ShowStaff = 'show_staff',
  CreateStaff = 'create_staff',
  UpdateStaff = 'update_staff',
  //HR department staff
  DepartmentStaff = 'department_staff',
  ListDepartmentStaff = 'list_department_staff',
  ShowDepartmentStaff = 'show_department_staff',
  CreateDepartmentStaff = 'create_department_staff',
  UpdateDepartmentStaff = 'update_department_staff',
  //HR staff degrees
  StaffDegrees = 'staff_degrees',
  ListStaffDegrees = 'list_staff_degrees',
  ShowStaffDegree = 'show_staff_degree',
  CreateStaffDegree = 'create_staff_degree',
  UpdateStaffDegree = 'update_staff_degree',
  //HR jobs history
  StaffHistories = 'staff_histories',
  ListStaffHistory = 'list_staff_histories',
  ShowStaffHistory = 'show_staff_history',
  CreateStaffHistory = 'create_staff_history',
  UpdateStaffHistory = 'update_staff_history',
  //HR Vacations
  Vacations = 'vacations',
  ListVacations = 'list_vacations',
  ShowVacation = 'show_vacation',
  CreateVacation = 'create_vacation',
  UpdateVacation = 'update_vacation',
  //HR Attendance
  Attendances = 'attendances',
  ListAttendances = 'list_attendances',
  ShowAttendance = 'show_attendance',
  CreateAttendance = 'create_attendance',
  UpdateAttendance = 'update_attendance',
  //HR User Work Hours
  UserWorkHours = 'user_work_hours',
  ListWorkHours = 'list_user_work_hours',
  ShowWorkHour = 'show_user_work_hour',
  CreateWorkHour = 'create_user_work_hour',
  UpdateWorkHour = 'update_user_work_hour',
  //HR Vacation Requests
  VacationRequests = 'vacation_requests',
  ListVacationRequests = 'list_vacation_requests',
  ShowVacationRequest = 'show_vacation_request',
  CreateVacationRequest = 'create_vacation_request',
  UpdateVacationRequest = 'update_vacation_request',
  //HR Loan Requests
  LoanRequests = 'loan_requests',
  ListLoanRequests = 'list_loan_requests',
  ShowLoanRequest = 'show_loan_request',
  CreateLoanRequest = 'create_loan_request',
  UpdateLoanRequest = 'update_loan_request',
  //buildings
  Buildings = 'buildings',
  ListBuildings = 'list_buildings',
  ShowBuilding = 'show_building',
  CreateBuilding = 'create_building',
  UpdateBuilding = 'update_building',
  //floors
  Floors = 'floors',
  ListFloors = 'list_floors',
  ShowFloor = 'show_floor',
  CreateFloor = 'create_floor',
  UpdateFloor = 'update_floor',
  //wards
  Wards = 'wards',
  ListWards = 'list_wards',
  ShowWard = 'show_ward',
  CreateWard = 'create_ward',
  UpdateWard = 'update_ward',
  //rooms types
  RoomTypes = 'room-types',
  ListRoomTypes = 'list_room-types',
  ShowRoomType = 'show_room-type',
  CreateRoomType = 'create_room-type',
  UpdateRoomType = 'update_room-type',
  //rooms
  Rooms = 'rooms',
  ListRooms = 'list_rooms',
  ShowRoom = 'show_room',
  CreateRoom = 'create_room',
  UpdateRoom = 'update_room',
  //academic years
  AcademicYears = 'academic-years',
  ListAcademicYears = 'list_academic-years',
  ShowAcademicYear = 'show_academic-year',
  CreateAcademicYear = 'create_academic-year',
  UpdateAcademicYear = 'update_academic-year',
  //requests settings
  RequestSettings = 'request-settings',
  ShowRequestSettings = 'show_request-setting',
  UpdateRequestSettings = 'update_request-setting',
  //academic degrees
  AcademicDegrees = 'academic-degrees',
  ListAcademicDegrees = 'list_academic-degrees',
  ShowAcademicDegree = 'show_academic-degree',
  CreateAcademicDegree = 'create_academic-degree',
  UpdateAcademicDegree = 'update_academic-degree',
  //academic semesters
  AcademicSemesters = 'academic-semesters',
  ListAcademicSemesters = 'list_academic-semesters',
  ShowAcademicSemester = 'show_academic-semester',
  CreateAcademicSemester = 'create_academic-semester',
  UpdateAcademicSemester = 'update_academic-semester',
  //academic terms
  AcademicTerms = 'academic-terms',
  ListAcademicTerms = 'list_academic-terms',
  ShowAcademicTerm = 'show_academic-term',
  CreateAcademicTerm = 'create_academic-term',
  UpdateAcademicTerm = 'update_academic-term',
  //academic classes
  AcademicClasses = 'academic-classes',
  ListAcademicClasses = 'list_academic-classes',
  ShowAcademicClass = 'show_academic-class',
  CreateAcademicClass = 'create_academic-class',
  UpdateAcademicClass = 'update_academic-class',
  //subjects
  Subjects = 'subjects',
  ListSubjects = 'list_subjects',
  ShowSubject = 'show_subject',
  CreateSubject = 'create_subject',
  UpdateSubject = 'update_subject',
  //appointment sections
  AppointmentSections = 'appointment-sections',
  ListAppointmentSections = 'list_appointment-sections',
  ShowAppointmentSection = 'show_appointment-section',
  CreateAppointmentSection = 'create_appointment-section',
  UpdateAppointmentSection = 'update_appointment-section',
  //appointment offices
  AppointmentOffices = 'appointment-offices',
  ListAppointmentOffices = 'list_appointment-offices',
  ShowAppointmentOffice = 'show_appointment-office',
  CreateAppointmentOffice = 'create_appointment-office',
  UpdateAppointmentOffice = 'update_appointment-office',
  //reserved appointments
  ReservedAppointments = 'reserved-appointments',
  ListReservedAppointments = 'list_reserved-appointments',
  ShowReservedAppointment = 'show_reserved-appointment',
  CreateReservedAppointment = 'create_reserved-appointment',
  UpdateReservedAppointment = 'update_reserved-appointment',
  //appointment offices schedules
  AppointmentOfficesSchedules = 'office-schedules',
  ListAppointmentOfficesSchedules = 'list_office-schedules',
  ShowAppointmentOfficeSchedule = 'show_office-schedule',
  CreateAppointmentOfficeSchedule = 'create_office-schedule',
  UpdateAppointmentOfficeSchedule = 'update_office-schedule',
  //appointment questions
  AppointmentQuestions = 'appointment-questions',
  ListAppointmentQuestions = 'list_appointment-questions',
  ShowAppointmentQuestion = 'show_appointment-question',
  CreateAppointmentQuestion = 'create_appointment-question',
  UpdateAppointmentQuestion = 'update_appointment-question',
  //transportation vehicles
  Vehicles = 'vehicles',
  ListVehicles = 'list_vehicles',
  ShowVehicle = 'show_vehicle',
  CreateVehicle = 'create_vehicle',
  UpdateVehicle = 'update_vehicle',
  //drivers
  Drivers = 'drivers',
  ListDrivers = 'list_drivers',
  ShowDriver = 'show_driver',
  CreateDriver = 'create_driver',
  UpdateDriver = 'update_driver',
  //transportation plans
  TransportationPlans = 'transportation-plans',
  ListTransportationPlans = 'list_transportation-plans',
  ShowTransportationPlan = 'show_transportation-plan',
  CreateTransportationPlan = 'create_transportation-plan',
  UpdateTransportationPlan = 'update_transportation-plan',
  //maintenance requests
  MaintenanceRequests = 'maintenance-requests',
  ListMaintenanceRequests = 'list_maintenance-requests',
  ShowMaintenanceRequest = 'show_maintenance-request',
  CreateMaintenanceRequest = 'create_maintenance-request',
  UpdateMaintenanceRequest = 'update_maintenance-request',
  //maintenance staff
  MaintenanceStaffs = 'maintenance-staff',
  ListMaintenanceStaffs = 'list_maintenance-staff',
  ShowMaintenanceStaff = 'show_maintenance-staff',
  CreateMaintenanceStaff = 'create_maintenance-staff',
  UpdateMaintenanceStaff = 'update_maintenance-staff',
  //maintenance specialties
  MaintenanceSpecialties = 'maintenance-specialties',
  ListMaintenanceSpecialties = 'list_maintenance-specialties',
  ShowMaintenanceSpecialty = 'show_maintenance_specialty',
  CreateMaintenanceSpecialty = 'create_maintenance_specialty',
  UpdateMaintenanceSpecialty = 'update_maintenance_specialty',
  //skill degrees
  SkillDegrees = 'skill-degrees',
  ListSkillDegrees = 'list_skill-degrees',
  ShowSkillDegree = 'show_skill-degree',
  CreateSkillDegree = 'create_skill-degree',
  UpdateSkillDegree = 'update_skill-degree',
  //work orders
  WorkOrders = 'work-orders',
  ListWorkOrders = 'list_work-orders',
  ShowWorkOrder = 'show_work-order',
  CreateWorkOrder = 'create_work-order',
  UpdateWorkOrder = 'update_work-order',
  //work logs
  WorkLogs = 'work-logs',
  ListWorkLogs = 'list_work-logs',
  ShowWorkLog = 'show_work-log',
  CreateWorkLog = 'create_work-log',
  UpdateWorkLog = 'update_work-log',
  //main services
  MainServices = 'main-services',
  ListMainServices = 'list_main-services',
  ShowMainService = 'show_main-service',
  CreateMainService = 'create_main-service',
  UpdateMainService = 'update_main-service',
  //sub services
  SubServices = 'sub-services',
  ListSubServices = 'list_sub-services',
  ShowSubService = 'show_sub-service',
  CreateSubService = 'create_sub-service',
  UpdateSubService = 'update_sub-service',
  //admission requests
  AdmissionRequests = 'admission-requests',
  ListAdmissionRequests = 'list_admission-requests',
  RegenerateAdmissionRequest = 'regenerate_admission-request',
  //admission periods
  AdmissionPeriods = 'admission-periods',
  ListAdmissionPeriods = 'list_admission-periods',
  ShowAdmissionPeriod = 'show_admission-period',
  CreateAdmissionPeriod = 'create_admission-period',
  UpdateAdmissionPeriod = 'update_admission-period',
  //required documents
  RequiredDocuments = 'required-documents',
  ListRequiredDocuments = 'list_required-documents',
  CreateRequiredDocuments = 'create_required-document',
  //questions
  Questions = 'questions',
  ListQuestions = 'list_questions',
  ShowQuestion = 'show_question',
  //withdrawal requests
  WithdrawalRequests = 'withdrawal-requests',
  ListWithdrawalRequests = 'list_withdrawal-requests',
  ShowWithdrawalRequest = 'show_withdrawal-request',
  CreateWithdrawalRequest = 'create_withdrawal-request',
  UpdateWithdrawalRequest = 'update_withdrawal-request',
  //withdrawal periods
  WithdrawalPeriods = 'withdrawal-periods',
  ListWithdrawalPeriods = 'list_withdrawal-periods',
  ShowWithdrawalPeriod = 'show_withdrawal-period',
  CreateWithdrawalPeriod = 'create_withdrawal-period',
  UpdateWithdrawalPeriod = 'update_withdrawal-period',
  //invoices
  Invoices = 'invoices',
  ListInvoices = 'list_invoices',
  //payment attempts
  PaymentAttempts = 'payment-attempts',
  ListPaymentAttempts = 'list_payment-attempts',
  ShowPaymentAttempt = 'show_payment-attempt',
  CreatePaymentAttempt = 'create_payment-attempt',
  UpdatePaymentAttempt = 'update_payment-attempt',
  //payment monitor
  PaymentMonitor = 'payment_monitor',
  SavePaymentMonitor = 'save_payment_monitor',
  ListPaymentMonitor = 'list_payment_monitor',
  ShowCheckPaymentMonitor = 'show_check_payment_monitor',
  AddCheckPaymentMonitor = 'add_check_payment_monitor',
  //students
  Students = 'students',
  ListStudents = 'list_students',
  //supply chain item groups
  ItemGroups = 'item_groups',
  ListItemGroups = 'list_item_groups',
  ShowItemGroup = 'show_item_group',
  CreateItemGroup = 'create_item_group',
  UpdateItemGroup = 'update_item_group',
  //supply chain units
  Units = 'units',
  ListUnits = 'list_units',
  ShowUnit = 'show_unit',
  CreateUnit = 'create_unit',
  UpdateUnit = 'update_unit',
  //supply chain item files
  ItemFiles = 'item_files',
  ListItemFiles = 'list_item_files',
  ShowItemFile = 'show_item_file',
  CreateItemFile = 'create_item_file',
  UpdateItemFile = 'update_item_file',
  //supply chain transaction types
  TransactionTypes = 'transaction_types',
  ListTransactionTypes = 'list_transaction_types',
  ShowTransactionType = 'show_transaction_type',
  CreateTransactionType = 'create_transaction_type',
  UpdateTransactionType = 'update_transaction_type',
  //supply chain transaction statuses
  TransactionStatuses = 'transaction_statuses',
  ListTransactionStatuses = 'list_transaction_statuses',
  ShowTransactionStatus = 'show_transaction_status',
  CreateTransactionStatus = 'create_transaction_status',
  UpdateTransactionStatus = 'update_transaction_status',
  //supply chain stores types
  StoreTypes = 'store_types',
  ListStoreTypes = 'list_store_types',
  ShowStoreType = 'show_store_type',
  CreateStoreType = 'create_store_type',
  UpdateStoreType = 'update_store_type',
  //supply chain stores
  Stores = 'stores',
  ListStores = 'list_stores',
  ShowStore = 'show_store',
  CreateStore = 'create_store',
  UpdateStore = 'update_store',
  //supply chain store items
  StoreItems = 'store_items',
  ListStoreItems = 'list_store_items',
  ShowStoreItem = 'show_store_item',
  CreateStoreItem = 'create_store_item',
  UpdateStoreItem = 'update_store_item',
  //supply chain transaction heads
  TransactionHeads = 'transaction_heads',
  ListTransactionHeads = 'list_transaction_heads',
  ShowTransactionHead = 'show_transaction_head',
  CreateTransactionHead = 'create_transaction_head',
  UpdateTransactionHead = 'update_transaction_head',
  //supply chain transaction details
  TransactionDetails = 'transaction_details',
  ListTransactionDetails = 'list_transaction_details',
  ShowTransactionDetail = 'show_transaction_detail',
  CreateTransactionDetail = 'create_transaction_detail',
  UpdateTransactionDetail = 'update_transaction_detail',
  //supply chain vendors categories
  VendorCategories = 'vendor_categories',
  ListVendorCategories = 'list_vendor_categories',
  ShowVendorCategory = 'show_vendor_category',
  CreateVendorCategory = 'create_vendor_category',
  UpdateVendorCategory = 'update_vendor_category',
  //supply chain vendors
  Vendors = 'vendors',
  ListVendors = 'list_vendors',
  ShowVendor = 'show_vendor',
  CreateVendor = 'create_vendor',
  UpdateVendor = 'update_vendor',
  //supply chain vendor officers
  VendorOfficers = 'vendor_officers',
  ListVendorOfficers = 'list_vendor_officers',
  ShowVendorOfficer = 'show_vendor_officer',
  CreateVendorOfficer = 'create_vendor_officer',
  UpdateVendorOfficer = 'update_vendor_officer',
  //supply chain vendor phones
  VendorPhones = 'vendor_phones',
  ListVendorPhones = 'list_vendor_phones',
  ShowVendorPhone = 'show_vendor_phone',
  CreateVendorPhone = 'create_vendor_phone',
  UpdateVendorPhone = 'update_vendor_phone',
  //assets groups
  AssetGroups = 'asset_groups',
  ListAssetGroups = 'list_asset_groups',
  ShowAssetGroup = 'show_asset_group',
  CreateAssetGroup = 'create_asset_group',
  UpdateAssetGroup = 'update_asset_group',
  //assets
  Assets = 'assets',
  ListAssets = 'list_assets',
  ShowAsset = 'show_asset',
  CreateAsset = 'create_asset',
  UpdateAsset = 'update_asset',
  // points
  Arbitrages = 'arbitrages',
  ListArbitrages = 'list_arbitrages',
  ShowArbitrage = 'show_arbitrage',
  CreateArbitrage = 'create_arbitrage',
  UpdateArbitrage = 'update_arbitrage',
  //banks
  Banks = 'banks',
  ListBanks = 'list_banks',
  ShowBanks = 'show_bank',
  CreateBanks = 'create_bank',
  UpdateBanks = 'update_bank',
  AcceptPayment = 'bank-payment-accept_admission-requests',
  RejectPayment = 'bank-payment-reject_admission-requests',
  //reports
  Reports = 'view-reports',
  ListReports = 'list_view-reports',
  ShowReports = 'show_view-report',
  CreateReports = 'create_view-report',
  UpdateReports = 'update_view-report',
  //clinic
  Clinics = 'clinics',
  ListClinics = 'list_clinics',
  ShowClinics = 'show_clinic',
  CreateClinics = 'create_clinics',
  UpdateClinics = 'update_clinics',
  //upgradeRequests
  UpgradeRequests = 'upgrade-requests',
  ListUpgradeRequests = 'list_upgrade-requests',
  ShowUpgradeRequests = 'show_upgrade-request',
  CreateUpgradeRequest = 'create_upgrade-request',
  UpdateUpgradeRequest = 'update_upgrade-request',
  RegenerateUpgradeRequest = 'regenerate_upgrade-request',
  //upgrade periods
  UpgradePeriods = 'upgrade-periods',
  ListUpgradePeriods = 'list_upgrade-periods',
  ShowUpgradePeriod = 'show_upgrade-period',
  CreateUpgradePeriod = 'create_upgrade-period',
  UpdateUpgradePeriod = 'update_upgrade-period',
  //transportationSubscriptionRequests
  TransportationSubscriptionRequests = 'transportation-subscription-requests',
  ListTransportationSubscriptionRequests = 'list_transportation-subscription-requests',
  ShowTransportationSubscriptionRequests = 'show_transportation-subscription-request',
  CreateTransportationSubscriptionRequest = 'create_transportation-subscription-request',
  UpdateTransportationSubscriptionRequest = 'update_transportation-subscription-request',
  //exceptionRequests
  ExceptionRequests = 'exception-requests',
  ListExceptionRequests = 'list_exception-requests',
  ShowExceptionRequests = 'show_exception-request',
  CreateExceptionRequest = 'create_exception-request',
  UpdateExceptionRequest = 'update_exception-request',
  AcceptExceptionRequest = 'accept_exception-request',
  UpdateExceptionRequestSettings = 'update_exception-request-settings',
  //sponsor requests
  SponsorRequests = 'sponsor-requests',
  ListSponsorRequests = 'list_sponsor-requests',
  ShowSponsorRequests = 'show_sponsor-request',
  CreateSponsorRequest = 'create_sponsor-request',
  UpdateSponsorRequest = 'update_sponsor-request',
  AcceptSponsorRequest = 'accept_sponsor-request',
  UpdateSponsorRequestSettings = 'update_sponsor-request-settings',
  //transfer requests
  TransferRequests = 'transfer-school-requests',
  ListTransferRequests = 'list_transfer-school-requests',
  ShowTransferRequest = 'show_transfer-school-request',
  CreateTransferRequest = 'create_transfer-school-request',
  UpdateTransferRequest = 'update_transfer-school-request',
  //Installment_view
  InstallmentView = 'installment_view',
  ListInstallmentView = 'list_installment_view',
  // income sources
  IncomeSources = 'income-sources',
  ListIncomeSources = 'list_income-sources',
  ShowIncomeSource = 'show_income-source',
  CreateIncomeSource = 'create_income-source',
  UpdateIncomeSource = 'update_income-source',
  // income
  Incomes = 'incomes',
  ListIncome = 'list_incomes',
  ShowIncome = 'show_income',
  CreateIncome = 'create_income',
  UpdateIncome = 'update_income',
  // expenses target
  ExpensesTargets = 'expenses-targets',
  ListExpensesTargets = 'list_expenses-targets',
  ShowExpenseTarget = 'show_expenses-target',
  CreateExpenseTarget = 'create_expenses-target',
  UpdateExpenseTarget = 'update_expenses-target',
  // expenses
  Expenses = 'expenses',
  ListExpenses = 'list_expenses',
  ShowExpense = 'show_expense',
  CreateExpense = 'create_expense',
  UpdateExpense = 'update_expense',
  // clients
  Clients = 'clients',
  ListClients = 'list_clients',
  ShowClient = 'show_client',
  CreateClient = 'create_client',
  UpdateClient = 'update_client',
  // contact officers
  ClientContactOfficers = 'client-contact-officers',
  ListClientContactOfficers = 'list_client-contact-officers',
  ShowClientContactOfficer = 'show_client-contact-officer',
  CreateClientContactOfficer = 'create_client-contact-officer',
  UpdateClientContactOfficer = 'update_client-contact-officer',
  //debts
  Debts = 'debts',
  ListDebts = 'list_debts',
  ShowDebts = 'show_debt',
  CreateDebt = 'create_debt',
  UpdateDebt = 'update_debt',
  ListStudentDebts = 'list_student_debts',
  PayStudentDebts = 'pay_student_debts',
  //sponsor
  Sponsors = 'sponsors',
  ListSponsors = 'list_sponsors',
  ShowSponsor = 'show_sponsor',
  CreateSponsor = 'create_sponsor',
  UpdateSponsor = 'update_sponsor',
  //sponsor contact officer
  SponsorContactOfficers = 'sponsor-contact-officers',
  ListSponsorContactOfficers = 'list_sponsor-contact-officers',
  ShowSponsorContactOfficer = '"show_sponsor-contact-officer',
  CreateSponsorContactOfficer = 'create_sponsor-contact-officer',
  UpdateSponsorContactOfficer = 'update_sponsor-contact-officer'
}
