import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-horizontal-line-chart',
  templateUrl: './horizontal-line-chart.component.html',
  styleUrls: ['./horizontal-line-chart.component.scss'],
})
export class HorizontalLineChartComponent implements OnChanges {
  @Input() progressData!: Array<{ status: string; total: number; config: any }>;

  public chartConfigs: any[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes['progressData']) {
      this.generateChartConfigs();
    }
  }

  generateChartConfigs() {
    this.chartConfigs = this.progressData.map((data) => {
      return data.config;
    });
  }
}
