import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[preventPaste]'
})
export class PreventPasteDirective {

  constructor(private el: ElementRef) { }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    event.preventDefault(); // Prevent default paste behavior
  }

}
