<div class="card widget-1">
  <div class="card-body">
    <div class="widget-content">
      <div class="widget-round {{data.font}}">
        <div class="bg-round">
          <svg class="svg-fill">
            <use attr.xlink:href="assets/svg/icon-sprite.svg#{{data.icon}}"></use>
          </svg>
          <svg class="half-circle svg-fill">
            <use href="assets/svg/icon-sprite.svg#halfcircle"></use>
          </svg>
        </div>
      </div>
      <div>
        <h4>{{data.counter}}</h4>
        <span class="f-light">{{data.name}}</span>
      </div>
    </div>
    <div class="font-{{data.font}} f-w-500"><i class="icon-arrow-up icon-rotate me-1"></i><span>{{data.pr}}%</span></div>
  </div>
</div>